import { isEmpty, omit } from 'lodash-es'

import { InsightsChartState } from '../jotai/charts.atoms'
import { CurrentSchemaVersions } from '../reports/CurrentSchemaVersions'
import { VisualizationType } from '../reports/store/report.molecule'
import { ChartConfig } from '../reports/types'
import {
  AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
  CHART_AGGREGATION_TYPES,
  CHART_TYPE
} from './chart_options'

/**
 * Returns the default title for a chart based on the given chart state.
 */
export function makeChartTitleFromChartState(chartState: InsightsChartState) {
  const { xAxisProperty, yAxisProperties } = chartState
  const { label: xLabel, units: xUnits } = xAxisProperty
  const xAxisTitle = xLabel
    ? `${xLabel}${xUnits ? ` [${xUnits}]` : ''}`
    : 'X Axis'
  const yAxisTitles = yAxisProperties
    .map(({ label, units }, index) =>
      label
        ? `${label.trim()}${units ? ` [${units}]` : ''}`
        : `Y Axis ${index + 1}`
    )
    .join(' / ')
    .trim()

  return `${yAxisTitles} vs ${xAxisTitle}`
}

/**
 * Returns the default description for a chart based on the given chart state.
 */
export function makeChartDescriptionFromChartState(
  chartState: InsightsChartState
) {
  const { xAxisProperty, yAxisProperties, normalizeByProperties, chartType } =
    chartState
  const { label: xLabel, units: xUnits } = xAxisProperty

  const descriptionLines = [
    `X-Axis Property: ${xLabel}${xUnits ? ` [${xUnits}]` : ''}`
  ]

  if (!isEmpty(normalizeByProperties)) {
    const xNormalization = normalizeByProperties[xAxisProperty.key]
    if (xNormalization) {
      descriptionLines.push(
        `X-Axis Normalization: ${xNormalization.label.trim()}${
          xNormalization.units ? ` [${xNormalization.units}]` : ''
        }`
      )
    }
  }

  yAxisProperties.forEach((property, index) => {
    descriptionLines.push(
      `Y-Axis ${index + 1} Property: ${property.label.trim()}${
        property.units ? ` [${property.units}]` : ''
      }`
    )

    if (!isEmpty(normalizeByProperties)) {
      const normalizedProperty = normalizeByProperties[property.key]
      if (normalizedProperty) {
        descriptionLines.push(
          `Y-Axis ${
            index + 1
          } Normalization: ${property.label.trim()} by ${normalizedProperty.label.trim()}${
            normalizedProperty.units ? ` [${normalizedProperty.units}]` : ''
          }`
        )
      }
    }
  })

  let chartTypeDescription: string

  switch (chartType) {
    case CHART_TYPE.LINE_MARKER:
      chartTypeDescription = 'Scatterplot'
      break
    case CHART_TYPE.LINE:
      chartTypeDescription = 'Line'
      break
    default:
      chartTypeDescription = 'Unknown'
      break
  }

  descriptionLines.push(`Chart Type: ${chartTypeDescription}`)

  return descriptionLines.join('\n')
}

/**
 * Convert InsightsChartState to ChartTemplate
 */
export function mapChartStateToChartTemplate(
  chartState: InsightsChartState
): ChartConfig {
  return {
    type: VisualizationType.Chart,
    schemaVersion: CurrentSchemaVersions.Chart,
    ...omit(
      chartState,
      'rawData',
      'datasets',
      'datasetColumns',
      'datasetIds',
      'normalizeOptions'
    )
  }
}

export const mapChartConfigV0ToV2 = (config: any): ChartConfig => {
  const { propertyFilters = {}, cycleFilters = {} } = config

  const processFilters = (filters: Record<string, any>) =>
    Object.keys(filters).reduce((acc: Record<string, any>, _filterId) => {
      const filter = filters[_filterId]
      acc[_filterId] = {
        ...filter,
        filter_type: filter.filterType,
        property:
          typeof filter.property === 'string'
            ? { key: filter.property }
            : filter.property
      }
      return acc
    }, {})

  return {
    type: VisualizationType.Chart,
    chartType: config.chartType,
    xPropertyRange: config.xPropertyRange,
    yPropertyRanges: Object.values(config.yPropertyRanges ?? []),
    xAxisProperty: { key: config.xAxisProperty, label: '' },
    yAxisProperties: config.yAxisProperties.map((key: string) => ({
      key,
      label: ''
    })),
    metricType: config.metricType,
    groupByProperty: { key: config.groupByProperty, label: '' },
    aggregateByProperty:
      CHART_AGGREGATION_TYPES.find(
        ({ key }) => key === config.aggregateByProperty
      ) ?? AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
    cycleFilters: processFilters(cycleFilters),
    propertyFilters: processFilters(propertyFilters),
    normalizeByProperties: config.normalizeByProperties ?? {},
    schemaVersion: CurrentSchemaVersions.Chart
  }
}
