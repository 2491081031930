import { useQuery } from '@apollo/client'
import Title from 'antd/es/typography/Title'
import { useAtom } from 'jotai'
import { compact, isEmpty, isEqual, isNil } from 'lodash-es'
import { nanoid } from 'nanoid'
import { ReactNode, useEffect, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

import { Skeleton } from '@/components'
import { emptyObject } from '@/constants'

import { use_workspace_and_org_ids } from '../../navigation/hooks/use_workspace_and_org_ids'
import { LOAD_METRIC_TARGET_LIMITS } from '../cell-specifications/queries/load_metric_target_limits'
import { GET_CYCLER_OBSERVATIONS } from '../charts/cycle_observations/queries/get_cycler_observations'
import { GET_CYCLE_SUMMARIES } from '../charts/cycle_summaries/queries/get_cycle_summaries'
import { ChartLegend } from '../charts/legends/ChartLegend'
import { InsightsChartTypes } from '../charts/types'
import { InsightsChartFilters } from '../controls/InsightsChartFilters'
import { apexChartOptionsAtom } from '../jotai/apex.atoms'
import { InsightsChartState, chartStatesFamily } from '../jotai/charts.atoms'

type InsightsChartProps = {
  chartId?: string
  datasets?: any[]
  datasetColumns?: any[]
  datasetIds: string[]
  showFilterControls?: boolean
  title?: ReactNode
  subTitle?: ReactNode
  state?: Partial<InsightsChartState>
  loading?: boolean
}

export const InsightsChart = (props: InsightsChartProps) => {
  const {
    chartId,
    datasets,
    datasetColumns,
    datasetIds,
    showFilterControls = true,
    title,
    subTitle,
    state,
    loading: externalLoading
  } = props
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const chartRef = useRef<ReactApexChart>(null)

  const [uniqueChartId] = useState(chartId ?? nanoid())
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: uniqueChartId, ...(state ?? emptyObject) })
  )

  const {
    cycleFilters,
    xAxisProperty,
    yAxisProperties,
    propertyFilters,
    normalizeByProperties,
    groupByProperty,
    aggregateByProperty,
    metricType
  } = chartState

  const [chartOptions] = useAtom(apexChartOptionsAtom(uniqueChartId))
  const { options } = chartOptions

  // gql
  const query =
    metricType === InsightsChartTypes.CYCLE_METRICS
      ? GET_CYCLE_SUMMARIES
      : GET_CYCLER_OBSERVATIONS

  const variables: Record<string, any> = {
    dataset_ids: datasetIds,
    organization_id: organization_id!,
    workspace_ids: [workspace_id!],
    additional_filter_keys: Object.values(propertyFilters).map(
      _propertyFilter => _propertyFilter.property?.key
    ),
    dataset_filters: [
      ...(Object.keys(propertyFilters)?.map(_propertyFilterId => {
        const propertyFilter = propertyFilters[_propertyFilterId]

        return {
          key: propertyFilter.property?.key,
          filter_type: propertyFilter.filter_type,
          value: propertyFilter.values
        }
      }) || [])
    ].filter(v => !isEmpty(v.key) && !isNil(v.value)), // Remove empty values as it causes a backend error
    y_properties: compact(yAxisProperties.map(property => property.key)), // Remove empty values as it causes a backend error
    x_property: xAxisProperty.key,
    group_by_property: (groupByProperty as any)?.key,
    aggregate_by: aggregateByProperty.key,
    normalize_by_properties: Object.entries(normalizeByProperties).map(
      ([axisPropertyKey, normalizeByProperty]) => ({
        axis_property: axisPropertyKey,
        normalize_by_property: {
          normalize_type: normalizeByProperty.type,
          value: normalizeByProperty.key
        }
      })
    )
  }

  if (metricType === InsightsChartTypes.IN_CYCLE_METRICS) {
    variables.cycle_filters =
      Object.keys(cycleFilters)?.map(_cycleFilterId => {
        const cycleFilter = cycleFilters[_cycleFilterId]

        return {
          key: cycleFilter.property?.key,
          filter_type: cycleFilter.filter_type,
          value: cycleFilter.values
        }
      }) || []

    // this is always hardcoded
    variables.additional_properties = ['mode']
  }

  if (metricType === InsightsChartTypes.CELL_METRICS) {
    variables.metric_target_limits = chartState.metricTargetLimits
    // Prevents request from failing due to missing cycle_filters and additional_properties
    variables.cycle_filters = []
    // this is always hardcoded
    variables.additional_properties = []
  }

  // Fetch chart raw data
  const [loadedOnce, setLoadedOnce] = useState(false)
  const { loading } = useQuery(query, {
    variables: variables as any,
    onCompleted(data: any) {
      let rawData = []
      let xAxisProperty = chartState.xAxisProperty
      let yAxisProperties = chartState.yAxisProperties
      switch (metricType) {
        case InsightsChartTypes.CYCLE_METRICS:
        case InsightsChartTypes.CELL_METRICS: {
          const dataObj = data?.get_cycle_summaries
          rawData = dataObj?.summary_groups ?? []
          xAxisProperty = dataObj?.x_property ?? xAxisProperty
          yAxisProperties = dataObj?.y_properties ?? yAxisProperties
          break
        }
        case InsightsChartTypes.IN_CYCLE_METRICS: {
          const dataObj = data?.get_cycler_observations
          rawData = dataObj?.datasets ?? []
          xAxisProperty = dataObj?.x_property ?? xAxisProperty
          yAxisProperties = dataObj?.y_properties ?? yAxisProperties
          break
        }
        default:
          rawData = []
      }

      setChartState(prev => ({
        ...prev,
        rawData,
        metricType,
        xAxisProperty,
        yAxisProperties
      }))
      setLoadedOnce(true)
    },
    onError: error => {
      console.error(error)
    },
    skip:
      !organization_id || !workspace_id || (isEmpty(datasetIds) && !loadedOnce)
  })

  // Fetch metric target limits - ONLY for cell metrics
  useQuery(LOAD_METRIC_TARGET_LIMITS, {
    variables: {
      organization_id: organization_id || '',
      workspace_id: workspace_id || ''
    },
    skip:
      metricType !== InsightsChartTypes.CELL_METRICS ||
      !organization_id ||
      !workspace_id,
    onCompleted: newMetricTargetLimits => {
      setChartState(prev => ({
        ...prev,
        metricTargetLimits:
          newMetricTargetLimits.load_metric_target_limits.metric_target_limits
      }))
    }
  })

  // // Update chartState when prop updates
  useEffect(() => {
    if (state == null) return
    setChartState((prev: InsightsChartState) => {
      const next = {
        ...prev,
        ...state,
        xAxisProperty: {
          label: state?.xAxisProperty?.label || prev?.xAxisProperty?.label,
          key: state?.xAxisProperty?.key || prev?.xAxisProperty?.key
        },
        yAxisProperties: (state?.yAxisProperties || []).map(
          (yProperty, index) => {
            return {
              label: yProperty?.label || prev?.yAxisProperties?.[index]?.label,
              key: yProperty?.key || prev?.yAxisProperties?.[index]?.key
            }
          }
        )
      }
      return isEqual(prev, next) ? prev : next
    })
  }, [setChartState, state])

  // Update chartState when datasets update
  useEffect(() => {
    setChartState((prev: InsightsChartState) => {
      const next = {
        ...prev,
        datasets: datasets || [],
        datasetColumns: datasetColumns || [],
        datasetIds
      }

      return isEqual(prev, next) ? prev : next
    })
  }, [datasets, datasetColumns, datasetIds, setChartState])

  const isAnythingLoading =
    !loadedOnce || externalLoading || loading || isEmpty(options.series)

  return (
    <div className='w-full'>
      <div className={`flex items-center ${!subTitle ? 'mb-2' : ''}`}>
        {title && (
          <Title level={5} className='!mb-0'>
            {title}
          </Title>
        )}
        <div className='flex-grow' />

        {showFilterControls && (
          <InsightsChartFilters chartId={uniqueChartId} chartRef={chartRef} />
        )}
      </div>

      {subTitle && (
        <div className='text-xxs text-gray-500 font-medium mb-4'>
          {subTitle}
        </div>
      )}

      <ChartLegend chartId={uniqueChartId} />

      {/**
       * When the chart is loading, show a skeleton, because when we don't, it is possible data
       * isn't finished processing or is still in an odd shape, and this can lead to chart crashes
       */}
      {isAnythingLoading ? (
        <ChartSkeleton />
      ) : (
        <ReactApexChart
          key={metricType}
          // @ts-expect-error
          options={options}
          type='line'
          series={options.series}
          height='440px'
          width='100%'
          ref={chartRef}
          loading={isAnythingLoading}
        />
      )}
    </div>
  )
}

function ChartSkeleton() {
  return (
    <div className='grid grid-cols-[1rem_1fr] gap-4'>
      <div className='h-[440px] flex gap-x-4 items-center'>
        <Skeleton.Node
          active
          className='!h-full [&.ant-skeleton-element]:!h-3/4 self-center [&_.ant-skeleton-image]:!w-4'
        />
      </div>
      <div>
        <Skeleton.Node
          active
          className='!h-full self-center [&.ant-skeleton-element]:!w-full [&_.ant-skeleton-image]:!w-full'
        />
      </div>
      <div></div>
      <div className='w-full flex items-center justify-center'>
        <Skeleton.Node
          active
          className='!w-3/4 [&_.ant-skeleton-image]:!h-4 [&_.ant-skeleton-image]:!w-full'
        />
      </div>
    </div>
  )
}

export default InsightsChart
