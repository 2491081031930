import { cloneDeep, isEmpty } from 'lodash-es'

import { CHART_COLORS } from '../../charts'
import { InsightsChartTypes } from '../../charts/types'
import { format_property_label } from '../../charts/utils'
import { InsightsChartState } from '../charts.atoms'
import { isAggregateByNone } from './shared'
import { ApexChartSeries, ApexChartSeriesLegendItem } from './types'

export const _getObservationsSeries = ({
  rawData,
  xAxisProperty,
  yAxisProperties,
  groupByProperty
}: Pick<
  InsightsChartState,
  'rawData' | 'xAxisProperty' | 'yAxisProperties' | 'groupByProperty'
>) => {
  const colors = cloneDeep(CHART_COLORS)
  const dashArray: number[] = []
  const _series: ApexChartSeries[] = []
  const _seriesLegendItems: ApexChartSeriesLegendItem[] = []
  const _yPropertySeriesNames: { [yPropertyIndex: number]: string[] } = {}

  const _isAggregateByNone = isAggregateByNone(
    InsightsChartTypes.IN_CYCLE_METRICS,
    groupByProperty,
    null
  )

  let colorIndex = 0
  // If no data, return empty series
  if (rawData.length === 0) {
    yAxisProperties.forEach(yProperty => {
      _series.push({
        name: format_property_label(yProperty.label, yProperty.units),
        id: yProperty.key,
        data: []
      })
    })
  }

  rawData.forEach((dataset: any) => {
    if (Array.isArray(dataset.cycles)) {
      dataset.cycles.forEach(({ series, cycle_number }: any) => {
        const group = `${dataset.dataset_id}: Cycle ${cycle_number}`

        yAxisProperties.forEach((yProperty, yPropertyIndex) => {
          const data: {
            x: number
            y: number | null
            range?: [number, number]
          }[] = []

          series
            .filter(
              (y_property_series: any) =>
                y_property_series.y_property === yProperty.key
            )[0]
            ?.observation_data.forEach(({ observation_properties }: any) => {
              const xValue = observation_properties.filter(
                (property: any) => property.key === xAxisProperty.key
              )?.[0]?.value

              let yValue: number | null = observation_properties.filter(
                (property: any) => property.key === yProperty.key
              )?.[0]?.value

              if (xValue !== null && yValue !== null) {
                data.push({ x: xValue, y: yValue })
              }
            })

          // If no data, return, because we don't want empty series being added to the chart
          // and it ends up skipping colors etc
          if (data.length === 0) return

          if (yPropertyIndex !== 0 && colorIndex !== 0) {
            // Use same color as previous series if this is the 2nd y-axis.
            // This is so that there is a consistent color for each dataset.
            const prevColor = colors[colorIndex - 1]
            colors.splice(colorIndex, 0, prevColor)
            dashArray.push(5)
          } else {
            dashArray.push(0)
          }

          const _seriesName = `${group} - ${format_property_label(
            yProperty.label,
            yProperty.units
          )}`

          if (_isAggregateByNone) {
            const seriesLegendName = `${dataset.aggregate_by_none_group}: Cycle ${cycle_number}`
            const existingSeriesGroup = _seriesLegendItems.find(
              item => item.name === seriesLegendName
            )

            if (!existingSeriesGroup) {
              // Only add a new series group if it doesn't already exist
              const color =
                CHART_COLORS[_seriesLegendItems.length % CHART_COLORS.length]
              _seriesLegendItems.push({
                name: seriesLegendName,
                color
              })

              colors[colorIndex] = color
            } else {
              colors[colorIndex] = existingSeriesGroup.color
            }

            _series.push({
              name: _seriesName,
              id: group,
              data
            })
          } else {
            _series.push({
              name: _seriesName,
              id: group,
              data
            })
            if (yPropertyIndex === 0) {
              const _seriesLegendName = `${group}`
              _seriesLegendItems.push({
                name: _seriesLegendName,
                color: colors[colorIndex]
              })
            }
          }

          // Save series name for y property
          _yPropertySeriesNames[yPropertyIndex] = [
            ...(_yPropertySeriesNames[yPropertyIndex] || []),
            _seriesName
          ]

          // Increment color index
          colorIndex++
        })
      })
    }
  })

  return {
    colors,
    dashArray,
    series: !isEmpty(_series)
      ? _series
      : yAxisProperties.map(yProperty => ({
          name: format_property_label(yProperty.label, yProperty.units),
          id: yProperty.key,
          data: []
        })),
    seriesLegendItems: _seriesLegendItems,
    yPropertySeriesNames: _yPropertySeriesNames
  }
}
