import { useSetAtom } from 'jotai'
import { PropsWithChildren, createContext, useContext } from 'react'

import {
  VisualizationType,
  visualizationAtomFamily
} from '../../store/report.molecule'
import { ChartConfig, TableConfig } from '../../types'

type VisualizationItemId = {
  visualizationId: string
}

type VisualizationItemContextOutput = VisualizationItemId & {
  /**
   * Updates the visualization configuration.
   *
   * @param config - Partial configuration object (`TableConfig` or `ChartConfig`).
   * @param exact - Optional flag to replace the entire configuration (`true`) or merge (`false`). Defaults to `false`.
   */
  updateVisualizationConfig: (
    config: Partial<TableConfig | ChartConfig>,
    exact?: boolean
  ) => void
}

const VisualizationItemContext =
  createContext<Nullable<VisualizationItemContextOutput>>(null)

/**
 * Provides context for a visualization item, allowing its configuration to be updated.
 *
 * @param props - The properties for the provider.
 * @param props.children - The child components to be rendered within the provider.
 * @param props.visualizationId - The unique identifier for the visualization item.
 *
 * @example
 * ```tsx
 * <VisualizationItemProvider visualizationId="chart1">
 *   <MyVisualizationComponent />
 * </VisualizationItemProvider>
 * ```
 */
export const VisualizationItemProvider = (
  props: PropsWithChildren<VisualizationItemId>
) => {
  const { children, visualizationId } = props

  const setVisualization = useSetAtom(
    visualizationAtomFamily({ id: visualizationId })
  )

  const updateVisualizationConfig = (
    config: Partial<TableConfig | ChartConfig>,
    exact = false
  ) => {
    setVisualization(prev => {
      const prevConfig = prev.config
      const nextConfig = exact
        ? config
        : {
            ...prevConfig,
            ...config
          }
      // @doelgonzo: I don't like this re-iterating the type definition,
      // but it's the only way to appease TypeScript
      if (prevConfig.type === VisualizationType.Chart) {
        return {
          ...prev,
          type: VisualizationType.Chart,
          config: nextConfig as ChartConfig
        }
      } else {
        return {
          ...prev,
          type: VisualizationType.Table,
          config: nextConfig as TableConfig
        }
      }
    })
  }

  return (
    <VisualizationItemContext.Provider
      value={{ visualizationId, updateVisualizationConfig }}
    >
      {children}
    </VisualizationItemContext.Provider>
  )
}

/**
 * Custom hook to access the VisualizationItemContext.
 *
 * This hook provides the current context value for the VisualizationItemContext.
 * It must be used within a VisualizationItemProvider; otherwise, it will throw an error.
 *
 * @throws {Error} If the hook is used outside of a VisualizationItemProvider.
 */
export const useVisualizationItem = () => {
  const context = useContext(VisualizationItemContext)
  if (!context) {
    throw new Error(
      'useVisualizationItem must be used within a VisualizationItemProvider'
    )
  }

  return context
}
