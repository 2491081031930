import { useAtomValue } from 'jotai'

import {
  InsightsChartTypeLabels,
  InsightsChartTypes
} from '@/insights/charts/types'

import { Select } from '../../../../../../components'
import { cn } from '../../../../../../utils'
import { DEFAULT_CHART_CONFIG_BY_TYPE } from '../../../../constants'
import {
  VisualizationType,
  visualizationAtomFamily
} from '../../../../store/report.molecule'
import { useVisualizationItem } from '../../VisualizationContext'

type SelectMetricTypeProps = { className?: string }

export const SelectMetricType = (props: SelectMetricTypeProps) => {
  const { className } = props
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()
  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, type } = visualization
  if (type !== VisualizationType.Chart) return null

  const { metricType } = config

  const onChange = (nextMetricType: InsightsChartTypes) => {
    updateVisualizationConfig(
      {
        ...DEFAULT_CHART_CONFIG_BY_TYPE[nextMetricType],
        metricType: nextMetricType
      },
      true
    )
  }

  const chartOptions = Object.values(InsightsChartTypes).map(value => ({
    value,
    label: InsightsChartTypeLabels[value]
  }))

  return (
    <Select
      options={chartOptions}
      placeholder='Select Metric Type'
      onChange={onChange}
      defaultValue={metricType}
      className={cn(
        'max-w-full w-full',
        `[&_.ant-select-selection-item]:font-semibold`,
        `[&_.ant-select-selection-search-input]:font-semibold`,
        `[&_.ant-select-selection-placeholder]:font-semibold`,
        className
      )}
    />
  )
}
