import { OverflowMenuHorizontal } from '@carbon/icons-react'
import { RefObject, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

import { Button, Popover } from '@/components'
import { InCyclePanelCycleFilters } from '@/insights/charts/cycle_observations/controls/InCyclePanelCycleFilters'
import { InsightsChartFilterPanelRoot } from '@/insights/controls/InsightsChartFilterPanelRoot'
import { InsightsPanelPropertyFilters } from '@/insights/controls/InsightsPanelPropertyFilters'
import { InsightsPanelXAxis } from '@/insights/controls/InsightsPanelXAxis'
import { InsightsPanelYAxis } from '@/insights/controls/InsightsPanelYAxis'

import { InsightsPanelGroupBy } from './InsightsPanelGroupBy'
import { InsightsPanelTarget } from './InsightsPanelTarget'
import { Menu_Nav_Provider, Menu_Panel } from './menus'
import { Menu_Panel_Id } from './menus/constants'

type InsightsChartFiltersProps = {
  chartId: string
  chartRef: RefObject<ReactApexChart>
}
export const InsightsChartFilters = (props: InsightsChartFiltersProps) => {
  const { chartId, chartRef } = props
  // Popover state
  const [popoverOpen, setPopoverOpen] = useState(false)
  const popoverRef = useRef<HTMLDivElement | null>(null)

  return (
    <>
      <Popover
        content={
          <div ref={popoverRef}>
            <Menu_Nav_Provider>
              <Menu_Panel panel_id={Menu_Panel_Id.ROOT}>
                <InsightsChartFilterPanelRoot
                  chartTitle='Chart configuration'
                  chartId={chartId}
                  chartRef={chartRef}
                />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.X_AXIS}>
                <InsightsPanelXAxis chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.Y_AXIS}>
                <InsightsPanelYAxis chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.GROUP_BY}>
                <InsightsPanelGroupBy chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.IN_CYCLE_FILTERS}>
                <InsightsPanelPropertyFilters chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.CYCLE_FILTERS}>
                <InCyclePanelCycleFilters chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.TARGET}>
                <InsightsPanelTarget chartId={chartId} />
              </Menu_Panel>
            </Menu_Nav_Provider>
          </div>
        }
        trigger={['click']}
        placement='bottomRight'
        overlayInnerStyle={{ padding: '0px 8px' }}
        open={popoverOpen}
      >
        <Button
          shape='circle'
          onClick={() => {
            setPopoverOpen(!popoverOpen)
          }}
          className='-mb-2'
        >
          <OverflowMenuHorizontal size={24} />
        </Button>
      </Popover>

      {popoverOpen && (
        <div
          className='fixed top-0 left-0 z-[1000] w-full h-full'
          onClick={() => {
            setPopoverOpen(false)
          }}
        />
      )}
    </>
  )
}
