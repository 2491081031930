import { PropsWithChildren, createContext, useContext, useState } from 'react'

export const MenuNavBase = {
  root: 'root'
} as const

type MenuNavBaseType = typeof MenuNavBase
type MenuNavPanelName = keyof MenuNavBaseType | string

type MenuNavContextType = {
  createSetSelectedPanelId: (panelId: string) => () => void
  returnToRoot: () => void
  selectedPanelId: MenuNavPanelName
  setSelectedPanelId: (id: MenuNavPanelName) => void
}

const defaultValue: MenuNavContextType = {
  createSetSelectedPanelId: () => () => {},
  returnToRoot: () => {},
  selectedPanelId: MenuNavBase.root,
  setSelectedPanelId: () => {}
}

const MenuNavContext = createContext(defaultValue)

export const useMenuNav = () => {
  // Ignoring rules of hooks as the linter doesn't recognize use_ as
  // a valid hook prefix.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(MenuNavContext)
  if (!context) {
    throw new Error('useMenuNav must be used within a MenuNavProvider')
  }
  return context
}

type MenuNavProviderProps = PropsWithChildren<{
  onPanelChange?: (panelId: MenuNavPanelName) => void
}>
export const MenuNavProvider = (props: MenuNavProviderProps) => {
  const { children, onPanelChange } = props
  const [selectedPanelId, setSelectedPanelId] = useState<MenuNavPanelName>(
    MenuNavBase.root
  )

  const createSetSelectedPanelId = (panelId: string) => () => {
    setSelectedPanelId(panelId)
    onPanelChange?.(panelId)
  }

  const returnToRoot = createSetSelectedPanelId(MenuNavBase.root)

  return (
    <MenuNavContext.Provider
      value={{
        createSetSelectedPanelId,
        returnToRoot,
        selectedPanelId,
        setSelectedPanelId
      }}
    >
      {children}
    </MenuNavContext.Provider>
  )
}
