import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'

import { BaseLayout } from '@/components'

import { fullReportAtomFamily } from '../store/report.molecule'
import { ReportRouteParams } from '../types'
import { ReportItem } from './ReportItem'

export const ReportPreview = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const [fullReport] = useAtom(fullReportAtomFamily(reportId ?? ''))

  if (fullReport == null) {
    console.error('Report not found')
    return null
  }
  return (
    <BaseLayout className='py-6'>
      <ReportItem report={fullReport} isPreview />
    </BaseLayout>
  )
}
