import { useQuery } from '@apollo/client'
import { useAtomValue } from 'jotai'
import { compact } from 'lodash-es'
import { useParams } from 'react-router-dom'

import { Normalize_Type, Property_Option } from '@/gql_generated/graphql'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '@/insights/charts/cycle_observations/queries/get_cycler_observation_filter_configs'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '@/insights/charts/cycle_summaries/queries/get_cycle_summary_properties'
import { PLOTTABLE_PROPERTY_TYPES } from '@/insights/types'

import { mapKeyToValue } from '../../../../../utils/mapKeyToValue'
import {
  VisualizationType,
  reportAtomFamily,
  visualizationAtomFamily
} from '../../../store/report.molecule'
import { ReportRouteParams } from '../../../types'
import { useVisualizationItem } from '../VisualizationContext'
import { SelectChartType } from './controls/SelectChartType'
import { PanelInCycleFilters, PanelXAxis, PanelYAxis } from './panels'
import { PanelSelectCycleFilters } from './panels/PanelSelectCycleFilters'

export function MenuInCycleChart() {
  const { reportId } = useParams<ReportRouteParams>()
  const report = useAtomValue(reportAtomFamily({ id: reportId }))
  const { datasetIds, workspaceId, organizationId } = report

  const { visualizationId } = useVisualizationItem()
  const { config } = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  let additionalFilterKeys: string[] = []

  if (config.type === VisualizationType.Chart) {
    const { propertyFilters } = config
    additionalFilterKeys = compact(
      Object.values(propertyFilters ?? {}).map(filter => filter.property?.key)
    )
  }

  const {
    data: cycleSummaryPropertiesData,
    loading: cycleSummaryPropertiesLoading
  } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organizationId as string
    },
    skip: !organizationId || !workspaceId
  })

  const {
    data: observationFilterConfigsData,
    loading: observationFilterConfigsLoading
  } = useQuery(GET_CYCLE_OBSERVATION_FILTER_CONFIGS, {
    variables: {
      organization_id: organizationId,
      workspace_ids: [workspaceId],
      dataset_ids: datasetIds || [],
      dataset_filters: [],
      additional_filter_keys: additionalFilterKeys
    },
    skip: !organizationId || !workspaceId
  })

  const datasetProperties: Property_Option[] =
    cycleSummaryPropertiesData?.get_cycle_summary_properties
      ?.dataset_properties ?? []

  const propertyOptions = mapKeyToValue(
    observationFilterConfigsData?.get_observation_filter_configs?.find(
      property => property.filter_property === 'plottable_observation_property'
    )?.options || []
  )

  const normalizeByPropertyOptions = mapKeyToValue(
    datasetProperties
      .filter(({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type))
      .map(({ key, label }) => ({
        key,
        label,
        type: Normalize_Type.DatasetProperty,
        group: 'Dataset Metrics'
      }))
  )

  const anyPropertiesLoading =
    observationFilterConfigsLoading || cycleSummaryPropertiesLoading

  if (config.type !== VisualizationType.Chart) return null
  return (
    <>
      <PanelXAxis
        propertyOptions={propertyOptions}
        normalizeOptions={normalizeByPropertyOptions}
        loading={anyPropertiesLoading}
      />
      <PanelYAxis
        propertyOptions={propertyOptions}
        normalizeOptions={normalizeByPropertyOptions}
        loading={anyPropertiesLoading}
      />
      <PanelSelectCycleFilters />
      <PanelInCycleFilters />
      <SelectChartType />
    </>
  )
}
