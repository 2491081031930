import {
  Box,
  ButtonProps,
  CSSObject,
  CssBaseline,
  Divider,
  IconButton,
  Drawer as MuiDrawer,
  Stack,
  Theme,
  Typography,
  styled
} from '@mui/material'
import { PropsWithChildren, useState } from 'react'

import { AccentColor, Byterat_Logo } from '../../components'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_COLLAPSED,
  NAV_BACKGROUND_COLOR,
  NAV_FOREGROUND_COLOR
} from './Left_Nav.constants'
import { User_Nav_Item } from './parts/User_Nav_Item'
import { Workspace_Nav_Items } from './parts/Workspace_Nav_Items'

export type Nav_State = {
  is_left_nav_open: boolean
}

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: NAV_BACKGROUND_COLOR,
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: NAV_BACKGROUND_COLOR,
  width: DRAWER_WIDTH_COLLAPSED,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden'
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 2.5),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: theme.spacing(8)
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

export const Left_Nav = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false)

  const toggleDrawer = () => {
    setOpen(prev => !prev)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'hidden',
        minHeight: '100vh'
      }}
    >
      <CssBaseline />
      <Drawer variant='permanent' open={open} component='nav'>
        <DrawerHeader>
          <Byterat_Brand
            onLogoClick={toggleDrawer}
            aria-label={open ? 'close drawer' : 'open drawer'}
            hideName={!open}
          />
        </DrawerHeader>
        <Stack justifyContent='space-between' sx={{ flexGrow: 1 }}>
          {/* Top anchored nav items */}
          <Stack>
            <Workspace_Nav_Items is_left_nav_open={open} />
          </Stack>
          {/* Bottom anchored nav items */}
          <Stack>
            <Divider />
            <User_Nav_Item is_left_nav_open={open} />
          </Stack>
        </Stack>
      </Drawer>
      <Main_View_Container component='main'>
        <Breadcrumbs />
        {children}
      </Main_View_Container>
    </Box>
  )
}

const Main_View_Container = styled(Box)`
  padding-top: 1.875rem;
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: auto;
` as typeof Box // See: https://github.com/mui/material-ui/issues/37551 and https://github.com/mui/material-ui/issues/38274

const Logo_Icon_Button = styled(IconButton)`
  border-radius: 100%;
  &:hover {
    background-color: ${NAV_FOREGROUND_COLOR};
  }
  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`
function Color_Shift_Logo_Icon_Button(props: ButtonProps) {
  const [hovered, setHovered] = useState(false)

  return (
    <Logo_Icon_Button
      color='inherit'
      {...props}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Byterat_Logo
        aria-label='Byterat Logo'
        accentColor={hovered ? AccentColor.Blue : AccentColor.White}
      />
    </Logo_Icon_Button>
  )
}

type Byterat_Brand_Props = {
  onLogoClick: () => void
  hideName?: boolean
}

function Byterat_Brand(props: Byterat_Brand_Props) {
  const { onLogoClick, hideName } = props
  return (
    <div className='flex gap-1 items-center'>
      <Color_Shift_Logo_Icon_Button
        aria-label='close drawer'
        onClick={onLogoClick}
      />

      <Typography
        sx={{
          fontFamily: 'Red Hat Display',
          color: NAV_FOREGROUND_COLOR,
          fontWeight: 500,
          opacity: hideName ? 0 : 1,
          transition: 'opacity 0.3s'
        }}
        variant='md'
        component='h2'
      >
        Byterat
      </Typography>
    </div>
  )
}
