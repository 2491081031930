import { useMutation, useQuery } from '@apollo/client'
import { TrashCan, WarningHexFilled } from '@carbon/icons-react'
import { LinearProgress, styled } from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridSlots
} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import { startCase } from 'lodash-es'
import { useMemo, useRef, useState } from 'react'

import { DateFormats } from '@/constants'
import { DELETE_CHART, GET_CHARTS } from '@/datasets/queries/charts'

import { BaseLayout, Button, Popconfirm, Typography } from '../../components'
import { use_workspace_and_org_ids } from '../../navigation/hooks/use_workspace_and_org_ids'
import { ChartListHeader } from './ChartListHeader'

const { Title } = Typography

const COLUMN_HEADER_HEIGHT = 80
const PAGE_SIZE_OPTIONS = [25, 50, 100]

enum ChartKeys {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  ChartConfig = 'chart_config',
  OrganizationId = 'organization_id',
  WorkspaceId = 'workspace_id',
  __Typename = '__typename'
}
const EXCLUDED_KEYS = [
  ChartKeys.__Typename,
  ChartKeys.OrganizationId,
  ChartKeys.WorkspaceId,
  ChartKeys.ChartConfig
]

const ValidKeys = Object.values(ChartKeys).filter(
  key => !EXCLUDED_KEYS.includes(key)
)

export const ChartList = () => {
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE_OPTIONS[0],
    page: 0
  })

  const queryVariables = {
    organization_id: organization_id as string,
    workspace_id: workspace_id as string,
    page: paginationModel.page + 1, // MUI Datagrid indexes pags from 0, but the backend indexes from 1
    page_size: paginationModel.pageSize
  }

  const { data, previousData, loading } = useQuery(GET_CHARTS, {
    variables: queryVariables,
    skip: !organization_id || !workspace_id
  })

  const [deleteChart] = useMutation(DELETE_CHART, {
    refetchQueries: [{ query: GET_CHARTS, variables: queryVariables }],
    awaitRefetchQueries: true
  })

  const makeDeleteChart = (id: string) => () => {
    deleteChart({ variables: { id } })
  }

  const previousRows = previousData?.get_charts.data ?? []
  const rows = data?.get_charts.data ?? []

  const rowsToRender = loading ? previousRows : rows

  const columnDefs: GridColDef[] = ValidKeys.sort((a, b) => {
    if (a === ChartKeys.Id) return 1
    if (b === ChartKeys.Id) return -1
    return 0
  }).map(key => ({
    field: key,
    minWidth: key === ChartKeys.Id ? 120 : 200,
    flex: key === ChartKeys.Id ? undefined : 1,
    disableReorder: key === ChartKeys.Id,
    sortable: key !== ChartKeys.Id,
    renderHeader: ({ field }) => {
      switch (field) {
        case ChartKeys.Id:
          return ''
        default:
          return startCase(field)
      }
    },
    renderCell: ({ value, row }) => {
      switch (key) {
        case ChartKeys.Title:
          return (
            <div className='font-semibold py-1 self-start overflow-hidden text-ellipsis'>
              {value}
            </div>
          )
        case ChartKeys.Description:
          return <pre className='text-xs py-1'>{value}</pre>
        case ChartKeys.CreatedAt:
        case ChartKeys.UpdatedAt:
          return (
            <div className='py-2 self-start'>
              {value !== null
                ? dayjs.utc(value).local().format(DateFormats.DATE_TIME_WITH_TZ)
                : null}
            </div>
          )

        case ChartKeys.Id:
          return (
            <div className='flex py-2 gap-x-2 self-start'>
              <Popconfirm
                key='delete'
                placement='topRight'
                title='Are you sure you want to delete this chart?'
                description={
                  <>
                    <div className='my-2 text-xs'>
                      <span className='font-mono font-medium mr-1 p-0.5 bg-yellow-100'>
                        {row.title}
                      </span>
                      will be
                      <span className='font-semibold mx-1'>permanently</span>
                      deleted
                    </div>
                    <div className='text-xs font-medium text-gray-500'>
                      This action cannot be undone
                    </div>
                  </>
                }
                onConfirm={makeDeleteChart(row.id)}
                okText='Yes'
                cancelText='No'
                icon={
                  <WarningHexFilled size={22} className='mr-1 text-amber-500' />
                }
              >
                <Button
                  type='text'
                  className='px-1.5 text-xs font-medium'
                  danger
                >
                  <TrashCan /> Delete
                </Button>
              </Popconfirm>
            </div>
          )

        default:
          return value
      }
    }
  }))

  const totalRowCount = data?.get_charts.total
  const rowCountRef = useRef(totalRowCount ?? 0)
  const rowCount = useMemo(() => {
    if (totalRowCount != null) {
      rowCountRef.current = totalRowCount
    }
    return rowCountRef.current
  }, [totalRowCount])

  return (
    <BaseLayout className='py-4 flex flex-col gap-y-4'>
      <div className='flex flex-row justify-between'>
        <Title level={3}>Chart Library</Title>
        {/* <Button type='primary' onClick={addReport}>
          <ReportData />
          Create Report
        </Button> */}
      </div>

      <div>
        <ChartListHeader rowCount={rowCount} loading={loading} />
        <Styled_Dataset_Datagrid
          columnHeaderHeight={COLUMN_HEADER_HEIGHT}
          columns={columnDefs}
          density='compact'
          disableColumnMenu
          disableDensitySelector
          disableRowSelectionOnClick
          filterDebounceMs={500}
          hideFooterSelectedRowCount
          key={workspace_id}
          loading={loading}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pagination
          paginationMode='server'
          paginationModel={paginationModel}
          rowCount={rowCount}
          rows={rowsToRender}
          getRowHeight={() => 'auto'}
          slots={{
            loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
            noResultsOverlay: () => null,
            noRowsOverlay: () => null
          }}
          slotProps={{
            pagination: {
              rowsPerPageOptions: PAGE_SIZE_OPTIONS.map(size => ({
                value: size,
                label: `${size} rows per page`
              })),
              labelRowsPerPage: null
            }
          }}
        />
      </div>
    </BaseLayout>
  )
}

const Styled_Dataset_Datagrid = styled(DataGridPro)(({ theme }) => ({
  height: 'max-content',
  minHeight: '50vh',
  maxHeight: 'calc(100vh - 170px)',
  borderRadius: theme.shape.borderRadius,
  '.MuiDataGrid-container--top': {
    backgroundColor: `${theme.palette.gray[100]}!important`
  },
  '.MuiDataGrid-main': {
    overflowY: 'auto'
  },
  '.MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center'
  },
  '.MuiDataGrid-row:hover': {
    background: theme.palette.gray[50]
  },
  '.MuiDataGrid-footerContainer': {
    border: 0,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  '.MuiTablePagination-root': {
    width: '100%',
    '.MuiTablePagination-toolbar': {
      border: 0,
      '.MuiInputBase-root': {
        border: 0,
        background: 'transparent',
        boxShadow: 'none',
        margin: 0
      },
      '.MuiSelect-select': {
        border: 0
      },
      '.MuiTablePagination-spacer': {
        display: 'none'
      },
      '.MuiTablePagination-displayedRows': {
        flex: 1,
        textAlign: 'right'
      }
    }
  }
}))
