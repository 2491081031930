type ObjectWithKeyProp = {
  key: string
  [key: string]: any
}

export function mapKeyToValue<T extends ObjectWithKeyProp>(
  objects: T[]
): (T & { value: string })[] {
  return objects.map(obj => ({ value: obj.key, ...obj }))
}
