import { DataAnalytics } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { useParams } from 'react-router-dom'

import { ReportRouteParams } from '@/insights/reports/types'

import {
  VisualizationType,
  reportAtomFamily,
  visualizationAtomFamily
} from '../../../store/report.molecule'
import { MenuHeader, ReportTable } from '../../controls'
import { useVisualizationItem } from '../VisualizationContext'
import { MenuDatasetColumns } from './MenuDatasetColumns'

export const ConfigureTable = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const report = useAtomValue(reportAtomFamily({ id: reportId }))
  const { visualizationId } = useVisualizationItem()

  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config } = visualization
  if (config.type !== VisualizationType.Table) return null

  const { datasetIds, workspaceId, organizationId } = report
  const { title } = visualization

  return (
    <div className='grid grid-cols-1 gap-8 lg:grid-cols-2'>
      <div className='flex flex-col flex-start'>
        <div className='grid grid-cols-2 gap-x-4 gap-y-8'>
          <MenuDatasetColumns config={config} />
        </div>
      </div>
      <div className='lg:border-l border-gray-200 lg:px-8'>
        <MenuHeader
          title={
            <div className='flex flex-row gap-x-2 items-center'>
              <DataAnalytics />
              Preview
            </div>
          }
        />
        <div className='bg-white p-4 overflow-hidden'>
          <ReportTable
            datasetIds={datasetIds}
            workspaceId={workspaceId}
            organizationId={organizationId}
            config={config}
            title={title}
            showEditControls={false}
          />
        </div>
      </div>
    </div>
  )
}
