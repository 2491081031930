import { useQuery } from '@apollo/client'
import { ChevronUp, Column, Search, View, ViewOff } from '@carbon/icons-react'
import { Divider } from 'antd'
import Fuse from 'fuse.js'
import { useAtomValue } from 'jotai'
import { isEmpty, startCase } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDebounceValue } from 'usehooks-ts'

import { Button, Input } from '@/components'
import { cn } from '@/utils'

import { GET_COLUMNS_FOR_INSIGHTS_DASHBOARD } from '../../../../../datasets/queries/get_datasets_for_insights_dashboard'
import { reportAtomFamily } from '../../../store/report.molecule'
import { ReportRouteParams, TableConfig } from '../../../types'
import { MenuHeader } from '../../controls'
import { useVisualizationItem } from '../VisualizationContext'
import { DatasetColumnItem } from './DatasetColumnItem'
import {
  AugmentedColumn,
  SelectedDatasetColumns
} from './SelectedDatasetColumns'
import { PanelGroupBy } from './panels/PanelGroupBy'

type MenuDatasetColumnsProps = {
  config?: TableConfig
}
export function MenuDatasetColumns(props: MenuDatasetColumnsProps) {
  const { config } = props
  const { reportId } = useParams<ReportRouteParams>()
  const report = useAtomValue(reportAtomFamily({ id: reportId }))
  const { workspaceId, organizationId } = report

  const [hideVisibleColumns, setHideVisibleColumns] = useState(false)

  const { updateVisualizationConfig } = useVisualizationItem()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 150)

  const { data, loading: propertiesLoading } = useQuery(
    GET_COLUMNS_FOR_INSIGHTS_DASHBOARD,
    {
      variables: {
        organization_id: organizationId,
        workspace_ids: [workspaceId]
      },
      skip: !organizationId || !workspaceId
    }
  )

  const datasetProperties: AugmentedColumn[] = useMemo(
    () =>
      (data?.get_datasets_list.columns ?? []).map(({ key, units }) => ({
        key,
        label: startCase(key),
        units,
        visible: config?.columns?.includes(key) ?? false
      })),
    [config?.columns, data?.get_datasets_list.columns]
  )

  const groupByOptions = useMemo(
    () =>
      datasetProperties.map(prop => ({
        value: prop.key,
        label: prop.label
      })),
    [datasetProperties]
  )

  const fuse = useMemo(() => {
    return new Fuse(datasetProperties, {
      keys: ['units', 'label', 'key']
    })
  }, [datasetProperties])

  const selectableOptions = useMemo(() => {
    if (debouncedSearchTerm != null && debouncedSearchTerm.length > 0) {
      return fuse.search(debouncedSearchTerm).map(({ item }) => item)
    }
    return datasetProperties
  }, [datasetProperties, fuse, debouncedSearchTerm])

  const selectedColumns = selectableOptions
    .filter(({ visible }) => visible)
    .sort((a, b) => {
      const aIndex = config?.columns?.indexOf(a.key) ?? -1
      const bIndex = config?.columns?.indexOf(b.key) ?? -1
      return aIndex - bIndex
    })
  const unselectedColumns = selectableOptions.filter(({ visible }) => !visible)
  const selectedColumnCount = selectedColumns.length

  const allHidden = datasetProperties.every(({ visible }) => !visible)

  const toggleColumnVisibility = (key: string) => {
    const newColumns = config?.columns?.includes(key)
      ? config.columns.filter(columnKey => columnKey !== key)
      : [...(config?.columns ?? []), key]

    updateVisualizationConfig({ columns: newColumns })
    setHideVisibleColumns(false)
  }

  const makeToggleColumnVisibility = (key: string) => () => {
    toggleColumnVisibility(key)
  }

  const onColumnsReordered = (columns: AugmentedColumn[]) => {
    updateVisualizationConfig({
      columns: columns.map(({ key }) => key)
    })
  }

  return (
    <div className='flex flex-col gap-2'>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <Column />
            Columns ({selectedColumnCount} selected)
          </div>
        }
      />
      <div className='flex flex-row gap-x-1'>
        <Input
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          prefix={<Search size={16} className='text-gray-500 mr-0.5' />}
          allowClear
        />
        {allHidden ? (
          <Button
            disabled={propertiesLoading}
            variant='text'
            color='primary'
            className='px-1 text-xs font-semibold'
            onClick={() => {
              updateVisualizationConfig({
                columns: datasetProperties.map(({ key }) => key)
              })
            }}
          >
            <View /> Show all
          </Button>
        ) : (
          <Button
            disabled={propertiesLoading}
            variant='text'
            color='primary'
            className='px-1 text-xs font-semibold'
            onClick={() => {
              updateVisualizationConfig({
                columns: []
              })
            }}
          >
            <ViewOff /> Hide all
          </Button>
        )}
      </div>
      <div className='bg-gray-50 p-2 gap-2 rounded-md'>
        <div className='h-80 overflow-y-auto overflow-x-hidden relative gap-y-2'>
          {selectedColumnCount > 0 && (
            <>
              <div
                className={cn(
                  'flex flex-col gap-y-1 transition-all ease-in-out duration-300 overflow-hidden',
                  {
                    'max-h-0': hideVisibleColumns,
                    'max-h-[1000px]': !hideVisibleColumns // Set a large enough max height to accommodate the content
                  }
                )}
              >
                <SelectedDatasetColumns
                  columns={selectedColumns}
                  groupByProperty={config?.groupByProperty}
                  onColumnsReordered={onColumnsReordered}
                  onToggleVisibility={toggleColumnVisibility}
                />
              </div>
              <Divider className='!my-2'>
                <Button
                  aria-label={
                    hideVisibleColumns
                      ? 'Show visible columns'
                      : 'Hide visible columns'
                  }
                  variant='filled'
                  color='default'
                  className='flex flex-row gap-x-1 text-xxs font-semibold text-gray-600'
                  size='small'
                  onClick={() => setHideVisibleColumns(prev => !prev)}
                >
                  Selected columns
                  <ChevronUp
                    size='1.25em'
                    className={cn('ml-0.5 transition-all ease-in-out', {
                      'transform rotate-0': !hideVisibleColumns,
                      'transform rotate-180': hideVisibleColumns
                    })}
                  />
                </Button>
              </Divider>
            </>
          )}
          <div className='flex flex-col gap-y-1'>
            {!isEmpty(unselectedColumns) ? (
              unselectedColumns.map(({ key, label, units, visible }) => (
                <DatasetColumnItem
                  key={key}
                  visible={visible}
                  label={label}
                  units={units}
                  isGroupKey={config?.groupByProperty === key}
                  onToggleVisibility={makeToggleColumnVisibility(key)}
                />
              ))
            ) : (
              <div className='self-center my-1 text-xs font-medium text-gray-500'>
                🎉 No unselected columns
              </div>
            )}
          </div>
        </div>
      </div>
      <PanelGroupBy
        selectedColumns={config?.columns || ([] as string[])}
        groupByOptions={groupByOptions}
        loading={propertiesLoading}
      />
    </div>
  )
}
