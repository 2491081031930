import { useAtomValue } from 'jotai'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { Button } from '@/components'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { use_app_selector } from '@/state/redux/hooks'

import { select_staged_dataset_table_rows } from '../home/insights_slice'
import { chartStatesFamily } from '../jotai/charts.atoms'
import {
  VisualizationType,
  reportAtomFamily,
  visualizationAtomFamily
} from '../reports/store/report.molecule'
import {
  makeChartTitleFromChartState,
  mapChartStateToChartTemplate
} from './transform'

type AddChartToReportProps = {
  chartId: string
  isInitialState?: boolean
  className?: string
}

export const AddChartToReport = (props: AddChartToReportProps) => {
  const { chartId, className, isInitialState = true } = props
  const navigate = useNavigate()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const chartState = useAtomValue(chartStatesFamily({ id: chartId }))

  const stagedDatasetTableRows = use_app_selector(
    select_staged_dataset_table_rows
  )

  const addChartToReport = () => {
    const chartId = nanoid()
    const reportId = uuidv4() // This is a requirement for the backend to store it correctly. We use nanoid elsewhere

    visualizationAtomFamily({
      id: chartId,
      type: VisualizationType.Chart,
      title: makeChartTitleFromChartState(chartState),
      config: mapChartStateToChartTemplate({ ...chartState, id: chartId })
    })
    reportAtomFamily({
      id: reportId,
      organizationId: organization_id,
      workspaceId: workspace_id,
      visualizationIds: [chartId],
      datasetIds: stagedDatasetTableRows.map(row => row.id)
    })
    navigate(`../reports/${reportId}`)
  }

  return (
    <Button
      className={className}
      disabled={isInitialState}
      onClick={addChartToReport}
      type='primary'
    >
      Add Chart to Report
    </Button>
  )
}
