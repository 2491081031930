import { gql } from '../../../../gql_generated'

export const GET_CYCLE_SUMMARY_PROPERTIES = gql(`
  query get_cycle_summary_properties(
    $organization_id: String!
  ) {
    get_cycle_summary_properties(
      organization_id: $organization_id
    ) {
      cycle_summary_properties {
        key
        label
        type
      }
      dataset_properties {
        key
        label
        type
        units
      }
    }
  }
`)
