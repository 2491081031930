import { DataCategorical } from '@carbon/icons-react'
import { SelectProps } from 'antd'
import { useAtomValue } from 'jotai'

import { Select } from '@/components'
import {
  VisualizationType,
  visualizationAtomFamily
} from '@/insights/reports/store/report.molecule'

import { MenuHeader, MenuItemControl } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

type PanelGroupByProps = {
  selectedColumns: string[]
  groupByOptions: SelectProps['options']
  loading?: boolean
}

/**
 * PanelYAxis component renders the Y-Axis configuration panel for a chart visualization.
 *
 * @param {PanelGroupBy} props - The properties for the PanelYAxis component.
 */
export function PanelGroupBy(props: PanelGroupByProps) {
  const { selectedColumns, groupByOptions, loading = true } = props
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()

  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, type } = visualization
  if (type !== VisualizationType.Table) return null

  const { groupByProperty } = config

  /**
   * Handles the change event for the Group By select input.
   *
   * @param {string} value - The selected value for the Group By property.
   */
  const onGroupByChange = (value: string) => {
    updateVisualizationConfig({ groupByProperty: value })

    // If the group by column is not in the list of selected columns, add it
    if (!selectedColumns || !selectedColumns.includes(value)) {
      updateVisualizationConfig({
        columns: [...selectedColumns, value]
      })
    }
  }

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <DataCategorical />
            Group By
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-2 items-stretch'>
          <MenuItemControl
            label='Group By'
            inputId='group-by-table'
            loading={loading}
            emphasize
          >
            <Select
              allowClear
              aria-labelledby='group-by-table'
              id='group-by-table'
              options={groupByOptions}
              placeholder='Select Metric'
              showSearch
              className='flex-1'
              value={groupByProperty}
              onChange={onGroupByChange}
            />
          </MenuItemControl>
        </div>
      </div>
    </div>
  )
}
