import { useQuery } from '@apollo/client'
import { Add, TrashCan } from '@carbon/icons-react'
import { Box, Button, Grid2 } from '@mui/material'
import { useAtom } from 'jotai'
import { useMemo } from 'react'

import { chartStatesFamily } from '@/insights/jotai/charts.atoms'

import {
  Filter_Config,
  Filter_Option,
  Normalize_Type,
  Property_Option
} from '../../gql_generated/graphql'
import { use_workspace_and_org_ids } from '../../navigation/hooks/use_workspace_and_org_ids'
import { use_app_selector } from '../../state/redux/hooks'
import { select_property_filter_keys } from '../charts/cycle_observations/cycler_observations_chart_slice'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '../charts/cycle_observations/queries/get_cycler_observation_filter_configs'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../charts/cycle_summaries/queries/get_cycle_summary_properties'
import {
  Absolute_Time_Byterat_Property,
  Non_Absolute_Time_Byterat_Property
} from '../charts/models/byterat_properties.model'
import { InsightsChartTypes } from '../charts/types'
import { select_staged_dataset_table_rows } from '../home/insights_slice'
import { PLOTTABLE_PROPERTY_TYPES } from '../types'
import { Autocomplete_Filter } from './filters/Autocomplete_Filter'
import Numeric_Filter from './filters/Numeric_Filter'
import { Menu_Header, Menu_Item_Control, use_menu_nav } from './menus'

const MAX_Y_AXIS_METRICS = 2

export function InsightsPanelYAxis({ chartId }: { chartId: string }) {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  // Jotai chart state
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )
  const {
    metricType,
    yAxisProperties,
    yPropertyRanges,
    normalizeByProperties
  } = chartState

  const additional_filter_keys = use_app_selector(select_property_filter_keys)
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )

  function handleYRangeChange(
    index: number,
    newRange: [number | null, number | null]
  ) {
    const _newYRanges = [...(yPropertyRanges || [])]
    _newYRanges[index] = newRange

    setChartState({
      ...chartState,
      yPropertyRanges: _newYRanges
    })
  }

  const {
    data: cycleSummaryPropertiesData,
    loading: cycleSummaryPropertiesLoading
  } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id
  })

  const {
    data: observationFilterConfigsData,
    loading: observationFilterConfigsLoading
  } = useQuery(GET_CYCLE_OBSERVATION_FILTER_CONFIGS, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      dataset_ids: (staged_dataset_table_rows || []).map(row => row.id),
      dataset_filters: [],
      additional_filter_keys
    },
    skip:
      !organization_id ||
      !workspace_id ||
      metricType !== InsightsChartTypes.IN_CYCLE_METRICS
  })

  // Compute the observation property options
  const observationPropertyOptions = useMemo(() => {
    let result: (Filter_Option | Property_Option)[] = []

    switch (metricType) {
      case InsightsChartTypes.IN_CYCLE_METRICS:
        result =
          observationFilterConfigsData?.get_observation_filter_configs?.find(
            (property: Filter_Config) =>
              property.filter_property === 'plottable_observation_property'
          )?.options || []
        break
      case InsightsChartTypes.CYCLE_METRICS:
        result =
          cycleSummaryPropertiesData?.get_cycle_summary_properties
            ?.cycle_summary_properties || []
        break
      case InsightsChartTypes.CELL_METRICS:
        result =
          cycleSummaryPropertiesData?.get_cycle_summary_properties?.dataset_properties?.filter(
            (property: Property_Option) =>
              property.type && PLOTTABLE_PROPERTY_TYPES.includes(property.type)
          ) || []
        break
      default:
        result = []
    }

    if (result.length !== 0) {
      return result.filter(
        // Remove any time-based properties
        (option: any) =>
          !Absolute_Time_Byterat_Property.includes(option?.key) &&
          !Non_Absolute_Time_Byterat_Property.includes(option?.key)
      )
    }

    return []
  }, [cycleSummaryPropertiesData, observationFilterConfigsData, metricType])

  const normalizeByOptions = useMemo(() => {
    const datasetProperties =
      cycleSummaryPropertiesData?.get_cycle_summary_properties
        ?.dataset_properties || []

    return datasetProperties
      .filter(({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type))
      .map(({ key, label, units }) => ({
        key,
        label,
        units,
        type: Normalize_Type.DatasetProperty,
        group: 'Dataset Metrics'
      }))
  }, [cycleSummaryPropertiesData])

  return (
    <>
      <Menu_Header title='Y-Axis' on_go_back={return_to_root} />
      <Box width={500} paddingBottom={2} paddingInline={1}>
        {yAxisProperties.map((filterOption, index) => {
          const _isTimeScale =
            Absolute_Time_Byterat_Property.includes(filterOption?.key) ||
            Non_Absolute_Time_Byterat_Property.includes(filterOption?.key)

          return (
            <>
              <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
                <Menu_Item_Control
                  input_id={`y-axis-${index}-type`}
                  label={`Y-Axis ${index + 1}`}
                  emphasize
                >
                  <Autocomplete_Filter
                    id={`y-axis-${index}-type`}
                    aria-labelledby={`y-axis-${index}-type`}
                    disableClearable
                    grid_item_size={7}
                    loading={
                      cycleSummaryPropertiesLoading ||
                      observationFilterConfigsLoading
                    }
                    multiple={false}
                    onChange={(_e: any, newYProperty: Filter_Option) => {
                      const _newYProperties = [...(yAxisProperties || [])]
                      _newYProperties[index] = newYProperty

                      const _newYRanges = [...(yPropertyRanges || [])]
                      _newYRanges[index] = [null, null]

                      setChartState({
                        ...chartState,
                        yAxisProperties: _newYProperties,
                        yPropertyRanges: _newYRanges
                      })
                    }}
                    options={observationPropertyOptions as Filter_Option[]}
                    placeholder='Add Metric'
                    value={filterOption}
                    getOptionDisabled={
                      yAxisProperties.length > 1
                        ? (option: Filter_Option) =>
                            yAxisProperties.some(
                              filter => filter.key === option.key
                            )
                        : undefined
                    }
                  />
                </Menu_Item_Control>

                <Menu_Item_Control
                  input_id={`y-axis-${index}-normalize-by`}
                  label='Normalize by'
                >
                  <Autocomplete_Filter
                    id={`y-axis-${index}-normalize-by`}
                    aria-labelledby={`y-axis-${index}-normalize-by`}
                    disableClearable={false}
                    grid_item_size={7}
                    loading={
                      cycleSummaryPropertiesLoading ||
                      observationFilterConfigsLoading
                    }
                    multiple={false}
                    onChange={(
                      _e: any,
                      newNormalizeBy: Filter_Option | null
                    ) => {
                      const _newNormalizeByProperties = normalizeByProperties
                      if (newNormalizeBy) {
                        _newNormalizeByProperties[filterOption.key] =
                          newNormalizeBy
                      } else {
                        delete _newNormalizeByProperties[filterOption.key]
                      }

                      setChartState({
                        ...chartState,
                        normalizeByProperties: _newNormalizeByProperties
                      })
                    }}
                    options={normalizeByOptions}
                    placeholder='Add Metric'
                    value={normalizeByProperties[filterOption.key]}
                  />
                </Menu_Item_Control>

                {_isTimeScale !== true && (
                  <Menu_Item_Control
                    input_id={`y-axis-${index}-limits`}
                    label='Limits (Min - Max)'
                  >
                    <Grid2
                      flexDirection='row'
                      columnGap={1}
                      display='inline-flex'
                      size={7}
                    >
                      <Numeric_Filter
                        placeholder='Min'
                        onBlur={event => {
                          const _parsedValue = Number(event.target.value)

                          handleYRangeChange(index, [
                            _parsedValue === 0
                              ? event.target.value === '0'
                                ? 0
                                : null
                              : _parsedValue,
                            yPropertyRanges?.[index]?.[1]
                          ])
                        }}
                        value={yPropertyRanges?.[index]?.[0] as number}
                      />

                      <Numeric_Filter
                        placeholder='Max'
                        onBlur={event => {
                          const _parsedValue = Number(event.target.value)

                          handleYRangeChange(index, [
                            yPropertyRanges?.[index]?.[0],
                            _parsedValue === 0
                              ? event.target.value === '0'
                                ? 0
                                : null
                              : _parsedValue
                          ])
                        }}
                        value={yPropertyRanges?.[index]?.[1] as number}
                      />
                    </Grid2>
                  </Menu_Item_Control>
                )}
              </Grid2>

              {yAxisProperties.length > 1 && (
                <Grid2
                  display='flex'
                  size='grow'
                  justifyContent='flex-end'
                  paddingInline={1}
                >
                  <Button
                    variant='text'
                    size='small'
                    startIcon={<TrashCan width={16} />}
                    onClick={() => {
                      const _newYProperties = yAxisProperties.filter(
                        (_filter, _index) => _index !== index
                      )

                      setChartState({
                        ...chartState,
                        yAxisProperties: _newYProperties
                      })
                    }}
                  >
                    Remove Y-Axis
                  </Button>
                </Grid2>
              )}
            </>
          )
        })}

        {yAxisProperties.length < MAX_Y_AXIS_METRICS && (
          <Button
            variant='text'
            size='small'
            startIcon={<Add width={16} />}
            onClick={() => {
              setChartState({
                ...chartState,
                yAxisProperties: [
                  ...yAxisProperties,
                  { key: '', label: '', units: '' }
                ]
              })
            }}
          >
            Add Y-Axis
          </Button>
        )}
      </Box>
    </>
  )
}
