import { gql } from '../../../../gql_generated'

export const GET_CYCLE_SUMMARIES = gql(`
  query get_cycle_summaries(
    $organization_id: String!
    $workspace_ids: [String!]!
    $dataset_ids: [String!]!
    $dataset_filters: [Filter!]!
    $x_property: String!
    $y_properties: [String!]!
    $group_by_property: String
    $normalize_by_properties: [Normalize_By_Properties_Arg!]!
    $aggregate_by: String!
  ) {
    get_cycle_summaries(
      organization_id: $organization_id
      workspace_ids: $workspace_ids
      dataset_ids: $dataset_ids
      dataset_filters: $dataset_filters
      x_property: $x_property
      y_properties: $y_properties
      group_by_property: $group_by_property
      normalize_by_properties: $normalize_by_properties
      aggregate_by: $aggregate_by
    ) {
      x_property {
        key
        label
        units
      }
      y_properties {
        key
        label
        units
      }
      normalize_by_property {
        key
        label
        units
      }
      summary_groups {
        group_id
        aggregate_by_none_group
        cycle_summary_data {
          value
          standard_deviation
          key
        }
      }
    }
  }
`)
