import { useQuery } from '@apollo/client'
import {
  Grid2,
  Breadcrumbs as MuiBreadcrumbs,
  Skeleton,
  styled
} from '@mui/material'
import { useAtomValue } from 'jotai'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { reportAtomFamily } from '@/insights/reports/store/report.molecule'

import { Iris_Organization, Workspace } from '../../gql_generated/graphql'
import { GET_ORGANIZATIONS } from '../../user/queries/get_organizations'
import { Breadcrumb_With_Menu } from './Breadcrumb_With_Menu'

const page_options = [
  { label: 'Playground', value: 'home' },
  { label: 'Reports', value: 'reports' },
  { label: 'Alerts', value: 'alerts' },
  { label: 'Upload Datasets', value: 'upload-datasets' }
]
export function Breadcrumbs() {
  const navigate = useNavigate()
  const location = useLocation()
  const { organization_key, workspace_key, reportId } = useParams()

  const report = useAtomValue(reportAtomFamily({ id: reportId }))

  const { data, error, loading } = useQuery(GET_ORGANIZATIONS, {
    onCompleted: ({ get_current_user_organizations }) => {
      let organization_key_param = organization_key || ''
      let workspace_key_param = workspace_key || ''

      // If no organization_key or workspace_key is provided, or organization_key is not included in the user's organizations,
      // redirect to the first organization and workspace
      if (
        !organization_key ||
        !get_current_user_organizations
          .map(org => org.key)
          .includes(organization_key)
      ) {
        organization_key_param = get_current_user_organizations[0].key
      }

      const workspaces = get_workspaces_from_organization(
        get_current_user_organizations,
        organization_key_param
      )
      const valid_workspaces_keys = workspaces.map(workspace => workspace.key)

      // If no workspace_key is provided, or workspace_key does not belong to the provided organization,
      // redirect to the first workspace of the provided organization
      if (!workspace_key || !valid_workspaces_keys.includes(workspace_key)) {
        workspace_key_param = valid_workspaces_keys[0]
      }
      const root_route = `/${organization_key_param}/${workspace_key_param}`
      if (!location.pathname.startsWith(root_route)) {
        navigate(root_route)
      }
    }
  })

  const organizations = data?.get_current_user_organizations || []
  const organization_keys = organizations.map(org => org.key)
  const valid_organization_key =
    organization_key && organization_keys.includes(organization_key)

  let workspaces: Workspace[] = []
  if (valid_organization_key) {
    workspaces = get_workspaces_from_organization(
      organizations,
      organization_key
    )
  }
  const workspace_keys = workspaces.map(workspace => workspace.key)
  const valid_workspace_key =
    workspace_key && workspace_keys.includes(workspace_key)

  if (error || loading || !valid_organization_key || !valid_workspace_key) {
    return (
      <Breadcrumb_Container container className='px-5'>
        <Grid2 size={12}>
          <MuiBreadcrumbs aria-label='breadcrumb'>
            <StyledChipSkeleton />
            <StyledChipSkeleton />
          </MuiBreadcrumbs>
        </Grid2>
      </Breadcrumb_Container>
    )
  }

  const organization_menu_options = organizations.map(org => ({
    label: org.name || '',
    value: org.key
  }))

  const workspace_menu_options = workspaces.map(workspace => ({
    label: workspace.name,
    value: workspace.key
  }))

  const selected_workspace_page =
    page_options.find(option => location.pathname.includes(`/${option.value}`))
      ?.value || ''

  // TODO: make this smarter than just having to add things to the breadcrumbs ourselves...
  const isReportsSubPage =
    location.pathname.includes('report') && reportId != null
  const reportTitle = report.title
  const reportSubPage = location.pathname.includes('item')
    ? reportTitle
    : 'Create Report'

  const isChartsPage = location.pathname.includes('charts')

  const handle_organization_selected = (organization_key: string) => {
    const default_workspace_key = get_workspaces_from_organization(
      organizations,
      organization_key
    )[0].key
    navigate(`/${organization_key}/${default_workspace_key}`)
  }

  const handle_workspace_selected = (workspace_key: string) => {
    navigate(`/${organization_key}/${workspace_key}`)
  }

  const handle_page_selected = (page: string) => {
    navigate(`/${organization_key}/${workspace_key}/${page}`)
  }

  return (
    <Breadcrumb_Container container className='px-5'>
      <Grid2 size={12}>
        <MuiBreadcrumbs aria-label='breadcrumb'>
          <Breadcrumb_With_Menu
            menu_options={organization_menu_options}
            selected_option_value={organization_key}
            on_option_selected={handle_organization_selected}
          />
          <Breadcrumb_With_Menu
            menu_options={workspace_menu_options}
            selected_option_value={workspace_key}
            on_option_selected={handle_workspace_selected}
          />
          {isChartsPage ? (
            <div className='h-6 text-[0.8125rem] flex items-center p-0.5 pb-0'>
              Chart Library
            </div>
          ) : (
            <Breadcrumb_With_Menu
              menu_options={page_options}
              selected_option_value={selected_workspace_page}
              on_option_selected={handle_page_selected}
            />
          )}
          {isReportsSubPage && (
            <div className='h-6 text-[0.8125rem] flex items-center p-0.5 pb-0'>
              {reportSubPage}
            </div>
          )}
        </MuiBreadcrumbs>
      </Grid2>
    </Breadcrumb_Container>
  )
}

const get_workspaces_from_organization = (
  organizations: Iris_Organization[],
  organization_key: string
): Workspace[] => {
  const organization = organizations.filter(
    organization => organization.key === organization_key
  )[0]

  if (!organization) return []

  return organization.workspaces || []
}

const Breadcrumb_Container = styled(Grid2)`
  margin-top: -0.875rem;

  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
  }
`

const StyledChipSkeleton = styled(Skeleton)(({ theme }) => {
  return {
    height: theme.spacing(3),
    width: theme.spacing(7),
    borderRadius: theme.spacing(8), // Use border radius larger than height to get a pill shape.
    transform: 'none' // Override default transform added by Mui.
  }
})
