import { ChartLineData, DataTable } from '@carbon/icons-react'
import { CollapseProps, Tooltip } from 'antd'
import { useAtom } from 'jotai'
import { isEmpty } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { AddChartTemplateToReport } from '@/insights/charts/AddChartTemplateToReport'
import { InsightsChartTypes } from '@/insights/charts/types'

import { Button, Collapse, Typography } from '../../../components'
import {
  DEFAULT_CHART_CONFIG_BY_TYPE,
  DEFAULT_TABLE_CONFIG
} from '../constants'
import {
  VisualizationType,
  fullReportAtomFamily,
  reportAtomFamily,
  visualizationAtomFamily
} from '../store/report.molecule'
import { ReportRouteParams, Visualization } from '../types'
import { ReportBuilderOutletContext } from './ReportBuilder'
import {
  VisualizationItem,
  VisualizationTitle
} from './visualizations/VisualizationItem'

const { Title } = Typography

const MAX_CHARTS_PER_REPORT = 3
const MAX_TABLES_PER_REPORT = 1

/**
 * Component for adding and managing visualizations within a report.
 *
 * This component allows users to add new visualizations (either tables or charts),
 * duplicate existing visualizations, and delete visualizations from a report.
 * It also manages the state of active panels for visualization editing.
 *
 * @example
 * <AddVisualizations />
 *
 * @remarks
 * - Uses `useParams` to get the report ID from the route parameters.
 * - Uses `useAtom` and `useSetAtom` from Jotai for state management.
 * - Supports adding tables and charts with default configurations.
 * - Prevents adding more than the maximum allowed number of charts or tables.
 * - Allows duplication of charts but not tables.
 * - Manages the state of active panels to ensure the correct panel is open during editing.
 */
export const AddVisualizations = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const { setCanContinue } = useOutletContext<ReportBuilderOutletContext>()

  const [report, setReport] = useAtom(reportAtomFamily({ id: reportId }))

  const [fullReport] = useAtom(fullReportAtomFamily(reportId ?? ''))
  const [activePanels, setActivePanels] = useState([report.visualizationIds[0]])
  const visualizations = fullReport?.visualizations ?? []

  const isAtMaxCharts =
    visualizations.filter(
      visualization => visualization?.type === VisualizationType.Chart
    ).length >= MAX_CHARTS_PER_REPORT

  const addVisualization = (viz: Partial<Visualization>) => {
    // If the visualization doesn't have an ID, generate one
    const vizId = viz.config?.id ?? nanoid()
    visualizationAtomFamily({
      id: vizId,
      ...viz
    })
    setReport(prev => ({
      ...prev,
      visualizationIds: [...prev.visualizationIds, vizId]
    }))
    setActivePanels(prev => [...prev, vizId])
  }

  const addTableVisualization = () => {
    addVisualization({
      title: 'Untitled Table',
      type: VisualizationType.Table,
      config: DEFAULT_TABLE_CONFIG
    })
  }

  const addChartVisualization = () => {
    addVisualization({
      title: 'Untitled Chart',
      type: VisualizationType.Chart,
      config: DEFAULT_CHART_CONFIG_BY_TYPE[InsightsChartTypes.IN_CYCLE_METRICS]
    })
  }

  const makeOnDuplicateVisualization = (id: string) => () => {
    const visualization = visualizations.find(v => v.id === id)
    if (visualization == null) return // This would indicate a bug
    if (visualization.type === VisualizationType.Table) return // We can't duplicate tables
    const { config } = visualization

    const newId = nanoid()
    visualizationAtomFamily({
      id: newId,
      title: `${visualization.title} (Copy)`,
      type: visualization.type,
      config: { ...config, id: newId }
    })
    setReport(prev => ({
      ...prev,
      visualizationIds: [...prev.visualizationIds, newId]
    }))
  }

  const makeOnDeleteVisualization = (id: string) => () => {
    visualizationAtomFamily.remove({ id })
    setReport(prev => ({
      ...prev,
      visualizationIds: prev.visualizationIds.filter(vId => vId !== id)
    }))
  }

  const visualizationItems: CollapseProps['items'] = report.visualizationIds
    .map(id => ({
      key: id,
      label: (
        <VisualizationTitle
          id={id}
          // Ensure that the current panel is active when editing to
          // prevent it from closing and losing potential context
          onEditStart={() =>
            setTimeout(() => setActivePanels(prev => [...prev, id]), 0)
          }
        />
      ),
      children: (
        <VisualizationItem
          id={id}
          canDuplicate={
            visualizations.find(v => v.id === id)?.type ===
              VisualizationType.Chart && !isAtMaxCharts
          }
          onDelete={makeOnDeleteVisualization(id)}
          onDuplicate={makeOnDuplicateVisualization(id)}
        />
      ),

      className: 'bg-white mb-2 !rounded-md border-none shadow-sm'
    }))
    .sort((a, b) => {
      const aType = visualizations.find(v => v.id === a.key)?.type
      const bType = visualizations.find(v => v.id === b.key)?.type
      if (aType === VisualizationType.Table) return -1
      if (bType === VisualizationType.Table) return 1
      return 0
    })

  useEffect(() => {
    const canContinue =
      visualizationItems.length > 0 &&
      !fullReport?.visualizations.some(
        visualization =>
          visualization.type === VisualizationType.Chart &&
          visualization.config.metricType ===
            InsightsChartTypes.IN_CYCLE_METRICS &&
          isEmpty(visualization.config.cycleFilters)
      )
    setCanContinue(canContinue)
  }, [visualizationItems, setCanContinue, fullReport?.visualizations])

  return (
    <>
      <div className='flex flex-row items-center gap-x-2 mb-4'>
        <Title level={5} className='!mb-0'>
          Add to Report
        </Title>

        <Tooltip title='Table'>
          <Button
            type='default'
            onClick={addTableVisualization}
            disabled={
              visualizations.filter(
                visualization => visualization?.type === VisualizationType.Table
              ).length >= MAX_TABLES_PER_REPORT
            }
          >
            <DataTable />
          </Button>
        </Tooltip>

        <div className='flex gap-0'>
          <Tooltip title='Chart'>
            <Button
              type='default'
              onClick={addChartVisualization}
              disabled={isAtMaxCharts}
              className='!rounded-r-none'
            >
              <ChartLineData />
            </Button>
          </Tooltip>
          <AddChartTemplateToReport
            onAddToReport={addVisualization}
            className='!rounded-l-none border-l-0 px-2'
          />
        </div>
      </div>
      {visualizationItems.length > 0 && (
        <Collapse
          bordered={false}
          items={visualizationItems}
          activeKey={activePanels}
          onChange={keys => setActivePanels(keys)}
          className='bg-transparent'
        />
      )}
    </>
  )
}
