import { useAtom } from 'jotai'
import { isEqual, uniqWith } from 'lodash-es'

import { apexChartOptionsAtom } from '../../jotai/apex.atoms'
import { CHART_TYPE } from '../chart_options'
import { Line_Icon } from './Line_Icon'
import { Marker_Icon } from './Marker_Icon'

export const ChartLegend = ({ chartId }: { chartId: string }) => {
  const [chartOptions] = useAtom(apexChartOptionsAtom(chartId))

  const chartType = chartOptions.type as CHART_TYPE
  const seriesLegendItems = uniqWith(
    chartOptions.options.seriesLegendItems,
    isEqual
  )
  const yAxes = chartOptions.options.yaxis

  return (
    <div className='text-xs'>
      {yAxes.length > 1 && (
        <div className='flex flex-wrap items-center gap-4'>
          {yAxes.map((yAxis, index) => {
            return (
              <div
                key={`${yAxis?.title?.text}-${index}`}
                className='flex items-center gap-2'
              >
                {chartType === CHART_TYPE.LINE ? (
                  <Line_Icon dash_array={yAxis.dashArray || 0} />
                ) : (
                  <Marker_Icon marker={yAxis.marker} />
                )}

                {yAxis?.title?.text}
              </div>
            )
          })}
        </div>
      )}

      <div className='mt-4 flex flex-wrap items-center gap-4'>
        {seriesLegendItems.map((seriesLegend, index) => (
          <div
            key={`${seriesLegend.name}-${index}`}
            className='flex items-center gap-2'
          >
            <Marker_Icon marker='circle' color={seriesLegend.color} />

            {seriesLegend.name}
          </div>
        ))}
      </div>
    </div>
  )
}
