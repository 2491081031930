import { useQuery } from '@apollo/client'
import { Box, Grid2 } from '@mui/material'
import { useAtom } from 'jotai'
import { useMemo } from 'react'

import { Filter_Option } from '@/gql_generated/graphql'
import { chartStatesFamily } from '@/insights/jotai/charts.atoms'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import { AGGREGATION_TYPE_NONE, CHART_AGGREGATION_TYPES } from '../charts'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../charts/cycle_summaries/queries/get_cycle_summary_properties'
import { InsightsChartTypes } from '../charts/types'
import { Autocomplete_Filter } from './filters/Autocomplete_Filter'
import { Menu_Header, Menu_Item_Control, use_menu_nav } from './menus'

export function InsightsPanelGroupBy({ chartId }: { chartId: string }) {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  // Jotai chart state
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )
  const { metricType, groupByProperty, aggregateByProperty } = chartState

  const { data, loading } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id
  })

  // Compute the observation property options
  const observationPropertyOptions = useMemo(() => {
    return (data as any)?.get_cycle_summary_properties?.dataset_properties || []
  }, [data])

  return (
    <>
      <Menu_Header title='Group By' on_go_back={return_to_root} />

      <Box width={400} paddingBottom={2}>
        <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
          <Menu_Item_Control label='Group By' input_id='x-axis-type' emphasize>
            <Autocomplete_Filter
              id='group-by-type'
              aria-labelledby='group-by-type'
              disableClearable={false}
              grid_item_size={7}
              loading={loading}
              multiple={false}
              onChange={(_e: any, newGroupByProperty: Filter_Option | null) => {
                setChartState({
                  ...chartState,
                  groupByProperty: newGroupByProperty
                })
              }}
              options={observationPropertyOptions}
              placeholder='Add Metric'
              value={groupByProperty}
            />
          </Menu_Item_Control>
          <Menu_Item_Control label='Aggregate by' input_id='aggregate-by'>
            <Autocomplete_Filter
              id='aggregate-by'
              aria-labelledby='aggregate-by'
              disableClearable
              disabled={
                metricType !== InsightsChartTypes.CYCLE_METRICS ||
                groupByProperty === null
              }
              grid_item_size={7}
              loading={loading}
              multiple={false}
              onChange={(_e: any, newAggregateByProperty: Filter_Option) => {
                setChartState({
                  ...chartState,
                  aggregateByProperty: newAggregateByProperty
                })
              }}
              options={CHART_AGGREGATION_TYPES}
              placeholder='Add Metric'
              value={
                metricType === InsightsChartTypes.CYCLE_METRICS
                  ? aggregateByProperty
                  : AGGREGATION_TYPE_NONE
              }
            />
          </Menu_Item_Control>
        </Grid2>
      </Box>
    </>
  )
}
