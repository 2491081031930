import { ApexOptions } from 'apexcharts'
import { map, uniq } from 'lodash-es'

import { Filter_Type, Property_Type } from '../../gql_generated/graphql'
import { SelectorOption } from '../types'

// Constants
export const DEFAULT_SAMPLE_SIZE = 500
export const DEFAULT_FILL_MARKER_SIZE = 2
export const DEFAULT_STROKE_MARKER_SIZE = 5
export const DEFAULT_MARKER_STROKE_WIDTH = 2
export const DEFAULT_LINE_STROKE_WIDTH = 2
export const DEFAULT_AREA_OPACITY = 0.25
export const MAX_Y_AXIS_METRICS = 2
export const CHART_SIG_FIGS = 4
export const CHART_DETAIL_SIG_FIG = 9

// Chart Colors
export const CHART_COLORS = [
  '#FFC209',
  '#B518ED',
  '#27EB00',
  '#D60098',
  '#00E3D4',
  '#D53208',
  '#B3D3FB',
  '#685488',
  '#29339B',
  '#F8F272',
  '#A4A768',
  '#D9C5A1',
  '#30548C',
  '#A4530E',
  '#BACC43',
  '#8F1C09',
  '#C88E00',
  '#00652C',
  '#CA0149',
  '#4DBDD4',
  '#5F0000',
  '#7D5A7E',
  '#3B6352',
  '#0CA9D8',
  '#3B6352',
  '#FF9EFF',
  '#000000',
  '#CEF09D',
  '#1080A5',
  '#05374D',
  '#FF00D0',
  '#005C6A',
  '#00E87E',
  '#424551',
  '#A9FF00',
  '#470098',
  '#00C7FF',
  '#B698F2',
  '#0C73CB',
  '#AFFF7F',
  '#FF8446',
  '#63FFD1'
]

// Chart Types
export enum CHART_TYPE {
  LINE = 'line',
  LINE_MARKER = 'line_marker',
  SCATTER = 'scatter'
}

// Marker Shapes
export const VALID_MARKER_SHAPES: MarkerShapeOptions[] = [
  'circle',
  'x',
  'rect',
  'plus'
]
export const VALID_SCATTER_SHAPES: MarkerShapeOptions[] = ['circle', 'rect']
export const MARKER_SHAPE_SIZE: Record<MarkerShapeOptions, number> = {
  circle: DEFAULT_FILL_MARKER_SIZE,
  square: DEFAULT_FILL_MARKER_SIZE,
  rect: DEFAULT_FILL_MARKER_SIZE + 2,
  x: DEFAULT_STROKE_MARKER_SIZE,
  X: DEFAULT_STROKE_MARKER_SIZE,
  plus: DEFAULT_STROKE_MARKER_SIZE + 1,
  '+': DEFAULT_STROKE_MARKER_SIZE + 1
}

// Interfaces
export interface OnAxesRangeChange {
  x_axis_range: [number | null, number | null]
  y_axes_ranges: { [key: number]: [number | null, number | null] }
  x_axis_type: string
  y_axes_types: { [key: number]: string }
}

interface Chart_Options {
  x_axis?: ApexXAxis
  y_axes?: ApexYAxis[]
  colors?: string[]
  dashes?: number[]
  strokes?: number[]
  opacities?: number[]
  marker_shapes?: MarkerShapeOptions[]
  marker_sizes?: number[]
  marker_stroke_colors?: string[]
  chart_type?: CHART_TYPE
  is_range_chart?: boolean
  on_zoom_change?: (
    x_range: [number | null, number | null],
    y_ranges: Record<string, [number | null, number | null]> | null
  ) => void
  on_axes_range_change?: (ranges: OnAxesRangeChange) => void
}

// Chart Options Creation
export const create_chart_options = ({
  x_axis,
  y_axes,
  colors,
  dashes,
  strokes,
  opacities,
  marker_shapes,
  marker_sizes,
  chart_type = CHART_TYPE.LINE,
  is_range_chart,
  on_zoom_change,
  on_axes_range_change
}: Chart_Options) => {
  let zoom_resetting = false

  const options: ApexOptions = {
    colors: colors || CHART_COLORS,
    stroke: {
      width:
        chart_type === CHART_TYPE.LINE
          ? strokes?.length
            ? strokes
            : DEFAULT_LINE_STROKE_WIDTH
          : 0,
      curve: 'straight',
      dashArray: dashes
    },
    markers: {
      size: [CHART_TYPE.LINE_MARKER, CHART_TYPE.SCATTER].includes(chart_type)
        ? marker_sizes?.length
          ? marker_sizes
          : DEFAULT_FILL_MARKER_SIZE
        : 0,
      strokeWidth: DEFAULT_MARKER_STROKE_WIDTH,
      strokeColors: colors || CHART_COLORS,
      shape: marker_shapes?.length ? marker_shapes : 'circle',
      radius: 0,
      hover: {
        sizeOffset: 0
      }
    },
    fill: {
      opacity: opacities?.length ? opacities : 1
    },
    legend: {
      show: false
    },
    chart: {
      zoom: {
        type: 'xy',
        enabled: true
      },
      toolbar: {
        autoSelected: 'zoom',
        tools: {
          pan: false,
          zoomin: false,
          zoomout: false
        }
      },
      animations: {
        enabled: false
      },
      events: {
        zoomed: function (_chart, { xaxis, yaxis }) {
          if (on_zoom_change && !zoom_resetting) {
            const y_axis_keys =
              yaxis !== undefined ? uniq(map(yaxis, 'group')) : []

            const y_axis_ranges: Record<
              string,
              [number | null, number | null]
            > = {}
            y_axis_keys.forEach((key, i) => {
              y_axis_ranges[key] = [yaxis[i].min, yaxis[i].max]
            })
            on_zoom_change([xaxis.min, xaxis.max], y_axis_ranges)
          }
          zoom_resetting = false
        },
        beforeResetZoom: function () {
          if (on_zoom_change) {
            zoom_resetting = true
            on_zoom_change([null, null], null)
          }
        },
        updated: on_axes_range_change
          ? chartContext => {
              if (!chartContext) {
                return
              }
              const newMinYValues = chartContext.w.globals.minYArr
              const newMaxYValues = chartContext.w.globals.maxYArr
              const newMinXValue = chartContext.w.globals.minX
              const newMaxXValue = chartContext.w.globals.maxX
              const xAxisType = chartContext.w.config.xaxis.type
              const yAxisTypes = chartContext.w.config.yaxis.map(
                (yaxis: any) => yaxis.type
              )
              let valuesChanged = false
              if (
                (x_axis?.min !== newMinXValue && x_axis?.min !== undefined) ||
                (x_axis?.max !== newMaxXValue && x_axis?.max !== undefined)
              ) {
                valuesChanged = true
              }
              newMinYValues.forEach((minValue: number, index: number) => {
                if (
                  (y_axes?.[index]?.min !== minValue &&
                    y_axes?.[index]?.min !== undefined) ||
                  (y_axes?.[index]?.max !== newMaxYValues[index] &&
                    y_axes?.[index]?.max !== undefined)
                ) {
                  valuesChanged = true
                }
              })
              if (valuesChanged) {
                const x_axis_range: [number | null, number | null] = [
                  newMinXValue,
                  newMaxXValue
                ]
                const y_axes_ranges: {
                  [key: number]: [number | null, number | null]
                } = {}
                newMinYValues.forEach((minValue: number, index: number) => {
                  y_axes_ranges[index] = [minValue, newMaxYValues[index]]
                })
                const y_axes_types: { [key: number]: string } = {}
                yAxisTypes.forEach((type: string, index: number) => {
                  y_axes_types[index] = type
                })
                on_axes_range_change({
                  x_axis_range,
                  y_axes_ranges,
                  x_axis_type: xAxisType,
                  y_axes_types
                })
              }
            }
          : undefined
      }
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: '',
      align: 'left'
    },
    tooltip: {
      shared: is_range_chart || chart_type === CHART_TYPE.LINE,
      marker: {
        show: chart_type === CHART_TYPE.LINE
      }
    },
    xaxis: x_axis || {},
    yaxis: y_axes || []
  }

  return options
}

// Property Types
export const Numeric_Property_Types = [Property_Type.Float, Property_Type.Long]

// Filter Options
export const KEYWORD_PROPERTY_OPERATOR_OPTIONS = [
  { label: '=', key: Filter_Type.IsAnyOf }
]

export const NUMERIC_PROPERTY_OPERATOR_OPTIONS = [
  { label: '=', key: Filter_Type.NumericEquals },
  { label: '>', key: Filter_Type.GreaterThan },
  { label: '≥', key: Filter_Type.GreaterThanOrEqual },
  { label: '<', key: Filter_Type.LessThan },
  { label: '≤', key: Filter_Type.LessThanOrEqual }
]

// Aggregation Types
export enum AggregationType {
  MEAN_PLUS_MINUS_STD = 'mean_plus_minus_std',
  NONE = 'none'
}
export const AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD: SelectorOption = {
  key: AggregationType.MEAN_PLUS_MINUS_STD,
  label: 'Mean +/- std'
}
export const AGGREGATION_TYPE_NONE: SelectorOption = {
  key: AggregationType.NONE,
  label: 'None'
}

export const CHART_AGGREGATION_TYPES: SelectorOption[] = [
  AGGREGATION_TYPE_NONE,
  AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD
]
