import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Chip, Menu, MenuItem, emphasize, styled } from '@mui/material'
import { MouseEvent, useState } from 'react'

interface Breadcrumb_With_Menu_Props {
  menu_options: { label: string; value: string }[]
  selected_option_value: string
  on_option_selected: (option: string) => void
}

export function Breadcrumb_With_Menu({
  menu_options,
  selected_option_value,
  on_option_selected
}: Breadcrumb_With_Menu_Props) {
  const [anchor_el, set_anchor_el] = useState<null | HTMLElement>(null)
  const open = Boolean(anchor_el)

  const handle_menu_open_click = (
    event: MouseEvent<HTMLElement>,
    anchor_to_parent = false
  ) => {
    const anchor_el = anchor_to_parent
      ? event.currentTarget.parentElement
      : event.currentTarget
    set_anchor_el(anchor_el)
  }
  const handle_option_click = (menu_option?: string) => {
    if (menu_option) {
      on_option_selected(menu_option)
    }
    set_anchor_el(null)
  }

  const selected_label = menu_options.filter(
    option => option.value === selected_option_value
  )[0]?.label

  return (
    <>
      <Styled_Breadcrumb
        label={selected_label}
        deleteIcon={<ExpandMoreIcon />}
        onDelete={event => handle_menu_open_click(event, true)}
        onClick={handle_menu_open_click}
      />
      <Menu
        anchorEl={anchor_el}
        open={open}
        onClose={() => handle_option_click()}
        disableScrollLock
      >
        {menu_options.map(option => (
          <MenuItem
            key={option.value}
            onClick={() =>
              handle_option_click(
                selected_option_value === option.value
                  ? undefined
                  : option.value
              )
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export const Styled_Breadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06)
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12)
    }
  }
}) as typeof Chip // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591
