import { useMutation, useQuery } from '@apollo/client'
import { ReportData, TrashCan, WarningHexFilled } from '@carbon/icons-react'
import { LinearProgress, styled } from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridSlots
} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import { startCase } from 'lodash-es'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { DateFormats } from '@/constants'
import { DELETE_REPORT, GET_REPORTS } from '@/datasets/queries/reports'

import { BaseLayout, Button, Popconfirm, Typography } from '../../components'
import { use_workspace_and_org_ids } from '../../navigation/hooks/use_workspace_and_org_ids'
import { ReportListHeader } from './ReportListHeader'
import {
  reportAtomFamily,
  visualizationAtomFamily
} from './store/report.molecule'
import { FullReportAtom } from './types'

const { Title } = Typography

const COLUMN_HEADER_HEIGHT = 80
const PAGE_SIZE_OPTIONS = [25, 50, 100]

enum ReportKeys {
  Id = 'id',
  Title = 'title',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  ReportQuery = 'report_query',
  OrganizationId = 'organization_id',
  WorkspaceId = 'workspace_id',
  __Typename = '__typename'
}
const EXCLUDED_KEYS = [
  ReportKeys.__Typename,
  ReportKeys.OrganizationId,
  ReportKeys.WorkspaceId
]

const ValidReportKeys = Object.values(ReportKeys).filter(
  key => !EXCLUDED_KEYS.includes(key)
)

export const ReportList = () => {
  const navigate = useNavigate()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE_OPTIONS[0],
    page: 0
  })

  const addReport = () => {
    const id = uuidv4() // This is a requirement for the backend to store it correctly. We use nanoid elsewhere
    reportAtomFamily({
      id,
      organizationId: organization_id,
      workspaceId: workspace_id
    })

    navigate(id)
  }

  const queryVariables = {
    organization_id: organization_id as string,
    workspace_id: workspace_id as string,
    page: paginationModel.page + 1, // MUI Datagrid indexes pags from 0, but the backend indexes from 1
    page_size: paginationModel.pageSize
  }

  const { data, previousData, loading } = useQuery(GET_REPORTS, {
    variables: queryVariables,
    skip: !organization_id || !workspace_id
  })

  const [deleteReport] = useMutation(DELETE_REPORT, {
    refetchQueries: [{ query: GET_REPORTS, variables: queryVariables }],
    awaitRefetchQueries: true
  })

  const makeDeleteReport = (id: string) => () => {
    deleteReport({ variables: { id } })
  }

  // Load reports into the Jotai store
  useEffect(() => {
    if (!data?.get_reports?.data) return
    data.get_reports.data.forEach(report => {
      const { report_query } = report
      const parsedReport = JSON.parse(report_query) as FullReportAtom

      parsedReport.visualizations.forEach(v => visualizationAtomFamily(v))
      reportAtomFamily({
        id: parsedReport.id,
        organizationId: parsedReport.organizationId,
        workspaceId: parsedReport.workspaceId,
        title: parsedReport.title,
        datasetIds: parsedReport.datasetIds,
        visualizationIds: parsedReport.visualizations.map(v => v.id)
      })
    })
  }, [data?.get_reports])

  const previousRows = previousData?.get_reports.data ?? []
  const rows = data?.get_reports.data ?? []

  const rowsToRender = loading ? previousRows : rows

  const columnDefs: GridColDef[] = ValidReportKeys.sort((a, b) => {
    if (a === ReportKeys.Id) return 1
    if (b === ReportKeys.Id) return -1
    return 0
  }).map(key => ({
    field: key,
    minWidth: key === ReportKeys.Id ? 120 : 200,
    flex: key === ReportKeys.Id ? undefined : 1,
    disableReorder: key === ReportKeys.Id,
    sortable: key !== ReportKeys.Id,
    renderHeader: ({ field }) => {
      switch (field) {
        case ReportKeys.Id:
          return ''
        case ReportKeys.ReportQuery:
          return 'Visualizations'
        default:
          return startCase(field)
      }
    },
    renderCell: ({ value, row }) => {
      switch (key) {
        case ReportKeys.Title:
          return (
            <Button
              onClick={() => navigate(`item/${row.id}`)}
              variant='link'
              color='primary'
              className='font-semibold px-0'
            >
              {value}
            </Button>
          )
        case ReportKeys.CreatedAt:
        case ReportKeys.UpdatedAt:
          return (
            <div className='text-sm'>
              {value !== null
                ? dayjs.utc(value).local().format(DateFormats.DATE_TIME_WITH_TZ)
                : null}
            </div>
          )
        case ReportKeys.ReportQuery:
          if (value == null) return value
          const report = JSON.parse(value) as FullReportAtom
          const chartCount = report.visualizations.filter(
            v => v.type === 'chart'
          ).length
          const tableCount = report.visualizations.filter(
            v => v.type === 'table'
          ).length

          return (
            <span>
              {chartCount !== 0 && (
                <>
                  {chartCount} Chart{chartCount !== 1 ? 's' : ''}
                </>
              )}

              {tableCount !== 0 && (
                <>
                  {chartCount !== 0 && ', '}
                  {tableCount} Table{tableCount !== 1 ? 's' : ''}
                </>
              )}
            </span>
          )
        case ReportKeys.Id:
          return (
            <div className='flex gap-x-2'>
              {/* <Button type='text'>
                  <AnalyticsCustom /> Edit
                </Button> */}
              <Popconfirm
                key='delete'
                placement='topRight'
                title='Are you sure you want to delete this report?'
                description={
                  <>
                    <div className='my-2 text-xs'>
                      <span className='font-mono font-medium mr-1 p-0.5 bg-yellow-100'>
                        {row.title}
                      </span>
                      will be
                      <span className='font-semibold mx-1'>permanently</span>
                      deleted
                    </div>
                    <div className='text-xs font-medium text-gray-500'>
                      This action cannot be undone
                    </div>
                  </>
                }
                onConfirm={makeDeleteReport(row.id)}
                okText='Yes'
                cancelText='No'
                icon={
                  <WarningHexFilled size={22} className='mr-1 text-amber-500' />
                }
              >
                <Button
                  type='text'
                  className='px-1.5 text-xs font-medium'
                  danger
                >
                  <TrashCan /> Delete
                </Button>
              </Popconfirm>
            </div>
          )

        default:
          return value
      }
    }
  }))

  const totalRowCount = data?.get_reports.total
  const rowCountRef = useRef(totalRowCount ?? 0)
  const rowCount = useMemo(() => {
    if (totalRowCount != null) {
      rowCountRef.current = totalRowCount
    }
    return rowCountRef.current
  }, [totalRowCount])

  return (
    <BaseLayout className='py-4 flex flex-col gap-y-4'>
      <div className='flex flex-row justify-between'>
        <Title level={3}>My Reports</Title>
        <Button type='primary' onClick={addReport}>
          <ReportData />
          Create Report
        </Button>
      </div>

      <div>
        <ReportListHeader rowCount={rowCount} loading={loading} />
        <Styled_Dataset_Datagrid
          columnHeaderHeight={COLUMN_HEADER_HEIGHT}
          columns={columnDefs}
          density='compact'
          disableColumnMenu
          disableDensitySelector
          disableRowSelectionOnClick
          filterDebounceMs={500}
          hideFooterSelectedRowCount
          key={workspace_id}
          loading={loading}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pagination
          paginationMode='server'
          paginationModel={paginationModel}
          rowCount={rowCount}
          rows={rowsToRender}
          slots={{
            loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
            noResultsOverlay: () => null,
            noRowsOverlay: () => null
          }}
          slotProps={{
            pagination: {
              rowsPerPageOptions: PAGE_SIZE_OPTIONS.map(size => ({
                value: size,
                label: `${size} rows per page`
              })),
              labelRowsPerPage: null
            }
          }}
        />
      </div>
    </BaseLayout>
  )
}

const Styled_Dataset_Datagrid = styled(DataGridPro)(({ theme }) => ({
  height: 'max-content',
  minHeight: '50vh',
  maxHeight: 'calc(100vh - 170px)',
  borderRadius: theme.shape.borderRadius,
  '.MuiDataGrid-container--top': {
    backgroundColor: `${theme.palette.gray[100]}!important`
  },
  '.MuiDataGrid-main': {
    overflowY: 'auto'
  },
  '.MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center'
  },
  '.MuiDataGrid-row:hover': {
    background: theme.palette.gray[50]
  },
  '.MuiDataGrid-footerContainer': {
    border: 0,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  '.MuiTablePagination-root': {
    width: '100%',
    '.MuiTablePagination-toolbar': {
      border: 0,
      '.MuiInputBase-root': {
        border: 0,
        background: 'transparent',
        boxShadow: 'none',
        margin: 0
      },
      '.MuiSelect-select': {
        border: 0
      },
      '.MuiTablePagination-spacer': {
        display: 'none'
      },
      '.MuiTablePagination-displayedRows': {
        flex: 1,
        textAlign: 'right'
      }
    }
  }
}))
