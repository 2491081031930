import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { nanoid } from 'nanoid'

import { idEquality } from '@/utils/idEquality'

import { Dataset_Grid_Row } from '../../home/insights_slice'
import { CurrentSchemaVersions } from '../CurrentSchemaVersions'
import { FullReportAtom, ReportAtom, Visualization } from '../types'

export enum VisualizationType {
  Chart = 'chart',
  Table = 'table'
}

/**
 * Visualization atom family, which creates a new visualization atom with a unique id, and returns
 * an atom for the existing visualization if the id is provided and the visualization exists.
 */
export const visualizationAtomFamily = atomFamily(
  ({ type, id, ...rest }: Partial<Visualization>) =>
    atom({
      id: id ?? nanoid(),
      type: type ?? VisualizationType.Chart,
      ...rest
    } as Visualization),
  idEquality
)

/**
 * Report atom family, which creates a new report atom with a unique id, and returns an atom for the
 * existing report if the id is provided and the report exists.
 */
export const reportAtomFamily = atomFamily(
  ({ id, ...rest }: Partial<ReportAtom>) =>
    atom({
      id: id ?? nanoid(),
      organizationId: '',
      workspaceId: '',
      visualizationIds: [] as Visualization['id'][],
      datasetIds: [] as Dataset_Grid_Row['id'][],
      schemaVersion: CurrentSchemaVersions.Report,
      ...rest
    }),
  idEquality
)

export const fullReportAtomFamily = atomFamily((id: string) =>
  atom(get => {
    const report = get(reportAtomFamily({ id }))

    if (report == null) return null

    // Get the visualizations for the report by getting the visualization atoms
    // using keys from the visualizationIds array
    const visualizations = report.visualizationIds.map(vId =>
      get(visualizationAtomFamily({ id: vId }))
    )
    // Remove visualizationIds from the report object
    const { visualizationIds, ...restReport } = report

    // Return the report object with the visualizations array
    return { ...restReport, visualizations } as FullReportAtom
  })
)
