import { ChevronDown, Export } from '@carbon/icons-react'
import { IconButton, Menu, MenuItem, styled, useTheme } from '@mui/material'
import { message } from 'antd'
import { useAtom, useAtomValue } from 'jotai'
import { RefObject, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

import { apexChartOptionsAtom } from '@/insights/jotai/apex.atoms'
import { visualizationAtomFamily } from '@/insights/reports/store/report.molecule'

const EXPORT_TYPES = ['none', 'csv', 'png', 'svg']
const VALID_EXPORT_TYPES = EXPORT_TYPES.slice(1)

export function ChartExportSelect(props: {
  chartId: string
  chartRef?: RefObject<ReactApexChart>
}) {
  const { chartId, chartRef } = props

  // Jotai chart options
  const [chartOptions] = useAtom(apexChartOptionsAtom(chartId))
  const visualization = useAtomValue(visualizationAtomFamily({ id: chartId }))
  const { options } = chartOptions

  const triggerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleMenuItemClick = (exportType: string) => {
    handleClose()

    // @ts-expect-error Not sure why the type for this isn't correct, but it works
    const _chartInstance = chartRef?.current?.chart as ApexCharts

    // Check if the chart instance exists
    if (_chartInstance == null) {
      message.error('Something went wrong')
      console.log("Couldn't find the chart instance", chartId)

      return
    }

    try {
      switch (exportType) {
        case 'csv':
          _chartInstance.exports.exportToCSV({
            fileName: `${visualization?.title ?? 'chart'}-${chartId}`,
            series: options.series,
            columnDelimiter: ','
          })
          break
        case 'png':
          _chartInstance.exports.exportToPng()
          break
        case 'svg':
          _chartInstance.exports.exportToSVG()
          break
        default:
          message.error('Invalid export type')
          break
      }
    } catch (e) {
      message.error('Something went wrong')
      console.error('Error exporting the chart', e)
    }
  }

  const theme = useTheme()

  return (
    <>
      <IconButton
        aria-controls={isOpen ? 'export-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        sx={{
          paddingBlock: theme.spacing(1),
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: theme.palette.grey[100]
          }
        }}
      >
        <div
          className='px-1 flex items-center gap-2 flex-1 font-bold'
          style={{
            color: theme.palette.text.primary,
            fontSize: theme.typography.sm
          }}
          ref={triggerRef}
        >
          <Export width={18} height={18} />
          <div className='flex-1 text-left'>Export</div>
          <ChevronDown width={16} />
        </div>
      </IconButton>
      <Menu
        id='export-menu'
        anchorEl={triggerRef.current}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'export-button',
          sx: { width: triggerRef.current?.clientWidth }
        }}
      >
        {VALID_EXPORT_TYPES.map(exportType => (
          <Menu_Item_Label
            key={exportType}
            onClick={() => handleMenuItemClick(exportType)}
          >
            {exportType.toUpperCase()}
          </Menu_Item_Label>
        ))}
      </Menu>
    </>
  )
}

const Menu_Item_Label = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.sm,
  paddingBlock: theme.spacing(1.25),
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[100],
    '&:hover,&:focus': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}))
