import { Draggable, View, ViewOff } from '@carbon/icons-react'
import { forwardRef } from 'react'

import { Button, Tag, Tooltip } from '@/components'

type DatasetColumnProps = {
  visible: boolean
  label: string
  units: string | null | undefined
  isGroupKey: boolean
  onToggleVisibility: () => void
}
export const DatasetColumnItem = forwardRef<HTMLDivElement, DatasetColumnProps>(
  (props, ref) => {
    const { visible, label, units, isGroupKey, onToggleVisibility } = props
    return (
      <div
        className='flex gap-x-2 overflow-hidden items-center select-none'
        ref={ref}
      >
        {visible && (
          <div className='flex items-center'>
            <Draggable className='text-gray-500 self-center' />
          </div>
        )}
        <Tag className='bg-white py-1 overflow-hidden items-baseline flex-1 self-start flex justify-between mr-0'>
          <div className='text-xs font-medium text-ellipsis overflow-hidden'>
            {label}
          </div>
          {units != null && (
            <Tooltip title={`Unit: ${units}`}>
              <div className='text-xs font-mono font-medium'>({units})</div>
            </Tooltip>
          )}
        </Tag>
        <Button
          disabled={visible && isGroupKey} // Disable visibility toggle if column is the group by property
          type='text'
          className='text-gray-400 self-center p-1 h-auto'
          onClick={onToggleVisibility}
        >
          {visible ? <ViewOff /> : <View />}
        </Button>
      </div>
    )
  }
)
DatasetColumnItem.displayName = 'DatasetColumnItem'
