import { Outlet } from 'react-router-dom'

import { Left_Nav } from './Left_Nav'
import { use_workspace_or_organization_change_listener } from './hooks/use_workspace_or_organization_change_listener'

export const Navigation_Root = () => {
  use_workspace_or_organization_change_listener()
  return (
    <Left_Nav>
      <Outlet />
    </Left_Nav>
  )
}
