import { SelectorOption } from '@/insights/types'

export const _getAxisTitle = (
  property: SelectorOption,
  normalizeByProperties: Record<string, SelectorOption | null>
) => {
  const axisPropertyTitle = `${property.label}${
    property.units ? ` [${property.units}]` : ''
  }`

  const normalizeByProperty = normalizeByProperties?.[property.key]
  const normalizeByPropertyTitle = normalizeByProperty
    ? `${normalizeByProperty.label}${
        normalizeByProperty.units ? ` [${normalizeByProperty.units}]` : ''
      }`
    : ''

  return normalizeByPropertyTitle
    ? `${axisPropertyTitle} / ${normalizeByPropertyTitle}`
    : axisPropertyTitle
}
