import {
  Filter_Option,
  Filter_Type,
  Property_Type
} from '../gql_generated/graphql'

export interface SelectorOption {
  key: any
  label: string
  group?: string
  type?: Nullable<string>
  units?: string | null
}

export type PropertyFilter = {
  filter_id: string
  filter_type?: Filter_Type
  property: Nullable<Filter_Option>
}

export type PropertyFilterWithValues = {
  filter_type: Filter_Type
  property: Nullable<Filter_Option>
  values: Filter_Option[]
}

export type CycleFilterWithValues = {
  filter_type: Filter_Type
  property: Nullable<Filter_Option>
  values: Filter_Option[] | string | number
}

export const PLOTTABLE_PROPERTY_TYPES = [
  Property_Type.Float,
  Property_Type.Long
]
