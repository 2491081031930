import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { cloneDeep, uniq } from 'lodash-es'
import numbro from 'numbro'

import {
  CHART_COLORS,
  CHART_DETAIL_SIG_FIG,
  CHART_TYPE,
  DEFAULT_FILL_MARKER_SIZE,
  DEFAULT_LINE_STROKE_WIDTH,
  DEFAULT_MARKER_STROKE_WIDTH,
  VALID_SCATTER_SHAPES
} from '../charts'
import { Absolute_Time_Byterat_Property } from '../charts/models/byterat_properties.model'
import { InsightsChartTypes } from '../charts/types'
import {
  ApexChartSeries,
  ApexChartSeriesLegendItem,
  _getAxisLabelFormatting,
  _getCellMetricsSeries,
  _getObservationsSeries,
  _getSummarySeries
} from './apexHelpers'
import { _getAxisTitle } from './apexHelpers/_getAxisTitle'
import { chartStatesFamily } from './charts.atoms'

const CATEGORICAL_PROPERTIES = ['dataset_cycle', 'cycle_number']

// Derived atom for chart options (replaces select_apex_chart_options)
export const apexChartOptionsAtom = atomFamily((id: string) =>
  atom(get => {
    const chartState = get(chartStatesFamily({ id })) // Full chart state
    const {
      aggregateByProperty,
      chartType,
      datasetColumns,
      datasetIds,
      datasets,
      groupByProperty,
      metricTargetLimits,
      metricTargetsApplied,
      metricType,
      normalizeByProperties,
      rawData,
      xAxisProperty,
      xPropertyRange,
      yAxisProperties,
      yPropertyRanges
    } = chartState

    const xAxisIsNormalized =
      normalizeByProperties?.[xAxisProperty.key]?.key != null

    // Configure various aspects of the chart based on the metric
    let colors: string[] = cloneDeep(CHART_COLORS)
    let computedY1Min: number | undefined = undefined
    let computedY1Max: number | undefined = undefined
    let dashArray: number[] = []
    let series: ApexChartSeries[] = []
    let markerShapes: string[] = []
    let markerSizes: number[] = []
    let yPropertySeriesNames: { [yPropertyIndex: number]: string[] } = {}
    let seriesLegendItems: ApexChartSeriesLegendItem[] = []

    switch (metricType) {
      case InsightsChartTypes.CYCLE_METRICS: {
        const seriesInfo = _getSummarySeries({
          rawData,
          xAxisProperty,
          yAxisProperties,
          groupByProperty,
          aggregateByProperty
        })

        colors = seriesInfo.colors
        dashArray = seriesInfo.dashArray
        series = seriesInfo.series
        seriesLegendItems = seriesInfo.seriesLegendItems
        yPropertySeriesNames = seriesInfo.yPropertySeriesNames
        break
      }
      case InsightsChartTypes.IN_CYCLE_METRICS: {
        const seriesInfo = _getObservationsSeries({
          rawData,
          xAxisProperty,
          yAxisProperties,
          groupByProperty
        })

        colors = seriesInfo.colors
        dashArray = seriesInfo.dashArray
        series = seriesInfo.series
        seriesLegendItems = seriesInfo.seriesLegendItems
        yPropertySeriesNames = seriesInfo.yPropertySeriesNames
        break
      }
      case InsightsChartTypes.CELL_METRICS: {
        const seriesInfo = _getCellMetricsSeries({
          datasets,
          datasetColumns,
          datasetIds,
          groupByProperty,
          xAxisProperty,
          yAxisProperties,
          normalizeByProperties
        })

        colors = seriesInfo.colors
        computedY1Min = seriesInfo.computedY1Min
        computedY1Max = seriesInfo.computedY1Max
        markerShapes = seriesInfo.markerShapes
        markerSizes = seriesInfo.markerSizes
        series = seriesInfo.series
        seriesLegendItems = seriesInfo.seriesLegendItems
        break
      }
    }

    /*
     * TARGET METRIC annotations
     */
    const annotations: ApexAnnotations = {
      yaxis: []
    }
    if (metricType === InsightsChartTypes.CELL_METRICS && metricTargetLimits) {
      const _matchingLimit = metricTargetLimits.find(
        ({ metric_name }) => yAxisProperties?.[0]?.key === metric_name
      )

      if (_matchingLimit) {
        const paddingPercentage = 0.1 // Set your desired padding percentage
        const computedRange =
          Math.max(computedY1Max || 0, _matchingLimit.upper_limit) -
          Math.min(computedY1Min || 0, _matchingLimit.lower_limit)

        const _limitsToApply = []
        if (metricTargetsApplied.includes('upper_limit')) {
          _limitsToApply.push(_matchingLimit.upper_limit)
        }
        if (metricTargetsApplied.includes('lower_limit')) {
          _limitsToApply.push(_matchingLimit.lower_limit)
        }
        _limitsToApply.forEach(limit => {
          if (limit !== undefined) {
            annotations.yaxis?.push({
              y: limit,
              strokeDashArray: 5,
              borderColor: 'black'
            })

            // Set computedY1Min with padding if it's undefined or limit is less than it
            if (computedY1Min === undefined || limit <= computedY1Min) {
              computedY1Min = parseFloat(
                (
                  limit -
                  (computedRange * paddingPercentage || Math.round(limit / 20))
                ).toFixed(1)
              )
            }

            // Set computedY1Max with padding if it's undefined or limit is greater than it
            if (computedY1Max === undefined || limit >= computedY1Max) {
              computedY1Max = parseFloat(
                (
                  limit +
                  (computedRange * paddingPercentage || Math.round(limit / 20))
                ).toFixed(1)
              )
            }
          }
        })
      }
    }

    // Configure X-axis options
    const xAxisType = Absolute_Time_Byterat_Property.includes(xAxisProperty.key)
      ? 'datetime'
      : CATEGORICAL_PROPERTIES.includes(xAxisProperty.key) && !xAxisIsNormalized
      ? 'category'
      : 'numeric'
    const apexXAxis: ApexXAxis = {
      title: {
        text: _getAxisTitle(xAxisProperty, normalizeByProperties)
      },
      min:
        typeof xPropertyRange[0] === 'number' ? xPropertyRange[0] : undefined,
      max:
        typeof xPropertyRange[1] === 'number' ? xPropertyRange[1] : undefined,
      // @ts-ignore
      labels: {
        ..._getAxisLabelFormatting(xAxisProperty.key, xAxisType)
      },
      type: xAxisType,
      hideOverlappingLabels: true
    }

    if (xAxisType === 'category') {
      const xValues = uniq(series.flatMap(s => s.data.map(d => d.x)))
      const tickAmount = xValues.length - 1
      apexXAxis.tickAmount = Math.min(tickAmount, 20)
    }

    // Configure Y-axis options for each Y property
    const apexYAxes = yAxisProperties
      .filter(({ key }) => !!key)
      .map((yProperty, index) => {
        const _markerShape = [
          InsightsChartTypes.CYCLE_METRICS,
          InsightsChartTypes.CELL_METRICS
        ].includes(metricType)
          ? VALID_SCATTER_SHAPES[index % VALID_SCATTER_SHAPES.length]
          : undefined

        let min =
          typeof yPropertyRanges?.[index]?.[0] === 'number'
            ? yPropertyRanges?.[index]?.[0]
            : undefined
        if (index === 0 && min === undefined && computedY1Min !== undefined) {
          min = computedY1Min
        }

        let max =
          typeof yPropertyRanges?.[index]?.[1] === 'number'
            ? yPropertyRanges?.[index]?.[1]
            : undefined
        if (index === 0 && max === undefined && computedY1Max !== undefined) {
          max = computedY1Max
        }

        return {
          title: {
            text: _getAxisTitle(yProperty, normalizeByProperties)
          },
          labels: {
            ..._getAxisLabelFormatting(yProperty.key)
          },
          min,
          max,
          opposite: index !== 0,
          marker: _markerShape,
          dashArray: index !== 0 ? 5 : 0,
          seriesName: yPropertySeriesNames[index],
          forceNiceScale: true
        }
      })

    const result = {
      options: {
        colors,
        stroke: {
          width: chartType === CHART_TYPE.LINE ? DEFAULT_LINE_STROKE_WIDTH : 0,
          curve: 'straight',
          dashArray
        },
        markers: {
          size: [CHART_TYPE.LINE_MARKER, CHART_TYPE.SCATTER].includes(chartType)
            ? markerSizes?.length
              ? markerSizes
              : DEFAULT_FILL_MARKER_SIZE
            : 0,
          strokeWidth: DEFAULT_MARKER_STROKE_WIDTH,
          strokeColors: colors,
          shape: markerShapes?.length ? markerShapes : 'circle',
          radius: 0,
          hover: {
            sizeOffset: 5
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false
        },
        chart: {
          id,
          type: chartType as ApexChart['type'],
          zoom: {
            type: 'xy',
            enabled: true
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          shared: metricType !== InsightsChartTypes.CELL_METRICS,
          intersect: metricType === InsightsChartTypes.CELL_METRICS,
          marker: {
            show: chartType === CHART_TYPE.LINE
          },
          x: {
            formatter: function (val: number, { w }: any) {
              const xAxisTitle = w.config.xaxis.title.text
              const formattedVal =
                xAxisType === 'category'
                  ? val
                  : numbro(val).format({
                      thousandSeparated: true,
                      mantissa: CHART_DETAIL_SIG_FIG,
                      trimMantissa: true
                    })

              return `${xAxisTitle}: <b>${formattedVal}</b>`
            }
          },
          y: {
            formatter: function (val: number) {
              return numbro(val).format({
                thousandSeparated: true,
                mantissa: CHART_DETAIL_SIG_FIG,
                trimMantissa: true
              })
            }
          }
        },
        xaxis: apexXAxis,
        yaxis: apexYAxes,
        annotations,
        series,
        seriesLegendItems
      },
      type: chartType as ApexChart['type']
    }

    return result
  })
)
