import { isBoolean, isEmpty, isNumber } from 'lodash-es'

/**
 * Removes null, undefined, or empty values from an object. Does not mutate the original object.
 *
 * @param obj The object to remove empty values from
 * @returns The object with empty values removed
 */
export function omitNullishOrEmptyValues(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => omitNullishOrEmptyValues(item))
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const value = omitNullishOrEmptyValues(obj[key])
      // Lodash treats numbers and booleans as empty, so we need to check for numbers separately
      if (isNumber(value) || isBoolean(value) || !isEmpty(value)) {
        acc[key] = value
      }
      return acc
    }, {} as any)
  }
  return obj
}
