import { Grid2 } from '@mui/material'
import { PropsWithChildren } from 'react'

import { Menu_Item_Input_Label } from './Menu_Item_Input_Label'

export function Menu_Item_Control(
  props: PropsWithChildren<{
    input_id: string
    label: string
    emphasize?: boolean
  }>
) {
  const { emphasize, input_id, label, children } = props
  return (
    <Grid2 size={12} container alignItems='center'>
      <Menu_Item_Input_Label
        grid_item_size='grow'
        htmlFor={input_id}
        emphasize={emphasize}
      >
        {label}
      </Menu_Item_Input_Label>
      {children}
    </Grid2>
  )
}
