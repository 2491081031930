import { useQuery } from '@apollo/client'
import { useAtomValue } from 'jotai'
import { useParams } from 'react-router-dom'

import { Normalize_Type, Property_Option } from '@/gql_generated/graphql'

import { CHART_AGGREGATION_TYPES } from '../../../../charts'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../../../../charts/cycle_summaries/queries/get_cycle_summary_properties'
import { PLOTTABLE_PROPERTY_TYPES } from '../../../../types'
import {
  VisualizationType,
  reportAtomFamily,
  visualizationAtomFamily
} from '../../../store/report.molecule'
import { ReportRouteParams } from '../../../types'
import { useVisualizationItem } from '../VisualizationContext'
import { SelectChartType } from './controls/SelectChartType'
import {
  PanelCycleFilters,
  PanelGroupBy,
  PanelXAxis,
  PanelYAxis
} from './panels'

export function MenuCycleChart() {
  const { reportId } = useParams<ReportRouteParams>()
  const report = useAtomValue(reportAtomFamily({ id: reportId }))
  const { workspaceId, organizationId } = report

  const { visualizationId } = useVisualizationItem()
  const { config } = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { data, loading: propertiesLoading } = useQuery(
    GET_CYCLE_SUMMARY_PROPERTIES,
    {
      variables: { organization_id: organizationId },
      skip: !organizationId || !workspaceId
    }
  )

  const cycleSummaryProperties = data?.get_cycle_summary_properties
  const datasetProperties: Property_Option[] =
    cycleSummaryProperties?.dataset_properties ?? []
  const summaryPropertyOptions =
    cycleSummaryProperties?.cycle_summary_properties ?? []

  const propertyOptions = summaryPropertyOptions.filter(
    ({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type)
  )

  const groupByPropertyOptions = datasetProperties

  const aggregateByOptions = CHART_AGGREGATION_TYPES

  const normalizeByPropertyOptions = datasetProperties
    .filter(({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type))
    .map(({ key, label }) => ({
      key,
      label,
      type: Normalize_Type.DatasetProperty,
      group: 'Dataset Metrics'
    }))

  if (config.type !== VisualizationType.Chart) return null
  return (
    <>
      <PanelXAxis
        propertyOptions={propertyOptions}
        normalizeOptions={normalizeByPropertyOptions}
        loading={propertiesLoading}
      />
      <PanelYAxis
        propertyOptions={propertyOptions}
        normalizeOptions={normalizeByPropertyOptions}
        loading={propertiesLoading}
      />
      <PanelGroupBy
        groupByOptions={groupByPropertyOptions}
        aggregateByOptions={aggregateByOptions}
        loading={propertiesLoading}
      />
      <PanelCycleFilters />
      <SelectChartType />
    </>
  )
}
