import { useAtomValue } from 'jotai'
import { isEqual, omit } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'

import { use_app_selector } from '../../state/redux/hooks'
import { InsightsChart } from '../__components/InsightsChart'
import {
  select_datasets,
  select_properties,
  select_staged_dataset_table_rows
} from '../home/insights_slice'
import { chartStatesFamily, initialChartState } from '../jotai/charts.atoms'
import { AddChartToLibrary } from './AddChartToLibrary'
import { AddChartToReport } from './AddChartToReport'
import { ApplyChartTemplate } from './ApplyChartTemplate'

export const InsightsChartView = () => {
  // Get all datasets
  const datasets = use_app_selector(select_datasets)
  const datasetColumns = use_app_selector(select_properties)
  const stagedDatasetTableRows = use_app_selector(
    select_staged_dataset_table_rows
  )

  const [chartId] = useState(nanoid())
  const chartState = useAtomValue(chartStatesFamily({ id: chartId }))

  const stagedDatasetIds = useMemo(
    () => stagedDatasetTableRows.map(row => row.id),
    [stagedDatasetTableRows]
  )

  // We must also compare the keys of the xAxisProperty and yAxisProperties array, because the keys are the unique identifiers
  // but _initialChartState will not know about most of the properties of the xAxisProperty and yAxisProperties objects, as
  // they are placeholders until the backend returns a full descriptor for the x/y properties (including units, __typename, etc.)
  const isInitialState = useMemo(() => {
    // We need to omit the datasetColumns and datasets from the comparison of knowing if the chart is in its initial state,
    // because the chart state will have the datasetColumns and datasets, but the initial state will not
    const baseState = { ...initialChartState, id: chartId }
    const _initialChartState = omit(baseState, 'datasetColumns', 'datasets')
    const _currentChartState = omit(chartState, 'datasetColumns', 'datasets')

    return isEqual(_initialChartState, _currentChartState)
  }, [chartId, chartState])

  return (
    <>
      <div className='absolute top-4 right-6 flex gap-x-2'>
        <ApplyChartTemplate chartId={chartId} />
        <AddChartToLibrary chartId={chartId} isInitialState={isInitialState} />
        <AddChartToReport chartId={chartId} isInitialState={isInitialState} />
      </div>
      <InsightsChart
        chartId={chartId}
        datasets={datasets}
        datasetColumns={datasetColumns}
        datasetIds={stagedDatasetIds}
      />
    </>
  )
}
