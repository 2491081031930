import { useSetAtom } from 'jotai'
import { isEqual } from 'lodash-es'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useOutletContext, useParams } from 'react-router-dom'

import { Typography } from '../../../components'
import { use_app_selector } from '../../../state/redux/hooks'
import { select_staged_dataset_table_rows } from '../../home/insights_slice'
import { Insights_Datasets_Table } from '../../tables/Insights_Datasets_Table'
import { reportAtomFamily } from '../store/report.molecule'
import { ReportRouteParams } from '../types'
import { ReportBuilderOutletContext } from './ReportBuilder'

const { Title } = Typography

export const SelectDatasets = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const { setCanContinue } = useOutletContext<ReportBuilderOutletContext>()

  const setReport = useSetAtom(reportAtomFamily({ id: reportId }))

  // This is a temporary solution to get the staged dataset table rows. In the future we don't want to
  // have to rely on the redux store for this data, but as of Oct/24/2024, we don't want to refactor the
  // Insights_Datasets_Table component to use jotai atoms.
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )

  useEffect(() => {
    setCanContinue(staged_dataset_table_rows.length > 0)

    setReport(prevReport => {
      const updatedReport = {
        ...prevReport,
        datasetIds: staged_dataset_table_rows.map(row => row.id)
      }

      return isEqual(prevReport, updatedReport) ? prevReport : updatedReport
    })
  }, [setCanContinue, setReport, staged_dataset_table_rows])

  return (
    <>
      <Title level={3}>Select Datasets</Title>
      <ErrorBoundary fallback={null}>
        <Insights_Datasets_Table />
      </ErrorBoundary>
    </>
  )
}
