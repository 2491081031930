import { Add, TrashCan, YAxis } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { FocusEvent, Fragment } from 'react'

import { Button, Select } from '@/components'
import { emptyArray } from '@/constants'
import {
  Filter_Option,
  Normalize_Type,
  Property_Option
} from '@/gql_generated/graphql'
import { Normalize_By_Option } from '@/insights/jotai/charts.atoms'
import { mapKeyToValue } from '@/utils'

import Numeric_Filter from '../../../../../controls/filters/Numeric_Filter'
import { RangeValue } from '../../../../constants'
import {
  VisualizationType,
  visualizationAtomFamily
} from '../../../../store/report.molecule'
import { MenuHeader, MenuItemControl } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

type PanelYAxisProps = {
  propertyOptions: (Property_Option | Filter_Option)[]
  normalizeOptions?: Normalize_By_Option[]
  loading?: boolean
}

const MAX_Y_AXIS_METRICS = 2

/**
 * PanelYAxis component renders the Y-Axis configuration panel for a chart visualization.
 *
 * @param {PanelYAxisProps} props - The properties for the PanelYAxis component.
 */
export function PanelYAxis(props: PanelYAxisProps) {
  const { propertyOptions, normalizeOptions = [], loading = true } = props
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()

  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, type } = visualization
  if (type !== VisualizationType.Chart) return null

  const {
    yAxisProperties = emptyArray,
    yPropertyRanges = emptyArray,
    normalizeByProperties
  } = config

  /**
   * Creates a handler for changing the metric of a Y-Axis.
   *
   * @param {number} index - The index of the Y-Axis.
   */
  const makeOnMetricChange = (index: number) => (newMetric: string) => {
    if (propertyOptions == null) return
    const newYAxisProperties = [...yAxisProperties]
    const newYMetric = propertyOptions.find(({ key }) => key === newMetric)
    if (newYMetric == null) return

    newYAxisProperties[index] = newYMetric
    updateVisualizationConfig({ yAxisProperties: newYAxisProperties })
  }

  /**
   * Creates a handler for changing the normalization metric of a Y-Axis.
   *
   * @param {number} index - The index of the Y-Axis.
   */
  const makeOnNormalizeByChange = (index: number) => (normalizeBy: string) => {
    const { key } = yAxisProperties[index]
    if (key) {
      updateVisualizationConfig({
        normalizeByProperties: {
          ...normalizeByProperties,
          [key]: {
            type: Normalize_Type.DatasetProperty,
            key: normalizeBy,
            label: normalizeBy
          }
        }
      })
    }
  }

  /**
   * Creates a handler for changing the range value of a Y-Axis.
   *
   * @param {number} index - The index of the Y-Axis.
   * @param {RangeValue} pos - The position (Min or Max) of the range value.
   */
  const makeOnRangeValueChange =
    (index: number, pos: RangeValue) =>
    (event: FocusEvent<HTMLInputElement, Element>) => {
      const value = parseFloat(event.target.value)
      const newYPropertyRanges = [...yPropertyRanges]
      newYPropertyRanges[index] = [
        pos === RangeValue.Min ? value : yPropertyRanges[index]?.[0],
        pos === RangeValue.Max ? value : yPropertyRanges[index]?.[1]
      ]
      updateVisualizationConfig({
        yPropertyRanges: newYPropertyRanges
      })
    }

  /**
   * Handler for adding a new Y-Axis.
   */
  const onAddAxis = () => {
    if (yAxisProperties.length >= MAX_Y_AXIS_METRICS) return
    updateVisualizationConfig({
      yAxisProperties: [...yAxisProperties, { key: '', label: '' }]
    })
  }

  /**
   * Creates a handler for removing a Y-Axis.
   *
   * @param {number} index - The index of the Y-Axis to remove.
   */
  const onRemoveAxis = (index: number) => () => {
    const newYAxisProperties = yAxisProperties.filter((_, idx) => idx !== index)
    updateVisualizationConfig({ yAxisProperties: newYAxisProperties })
  }

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <YAxis />
            Y-Axis
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-2 items-stretch'>
          {yAxisProperties.map((yAxis, index) => (
            <Fragment key={index}>
              <MenuItemControl
                label={`Y-Axis ${index + 1}`}
                inputId={`y-axis-type-${index}`}
                loading={loading}
                emphasize
              >
                <Select
                  allowClear={false}
                  aria-labelledby={`y-axis-type-${index}`}
                  id={`y-axis-type-${index}`}
                  options={mapKeyToValue(propertyOptions)}
                  placeholder='Add Metric'
                  showSearch
                  className='flex-1'
                  value={yAxis}
                  onChange={makeOnMetricChange(index)}
                />
              </MenuItemControl>
              <MenuItemControl
                label={`Normalize by`}
                inputId={`y-axis-normalize-by-${index}`}
                loading={loading}
              >
                <Select
                  allowClear
                  aria-labelledby={`y-axis-normalize-by-${index}`}
                  id={`y-axis-normalize-by-${index}`}
                  options={mapKeyToValue(normalizeOptions)}
                  placeholder='Add Metric'
                  showSearch
                  className='flex-1'
                  onChange={makeOnNormalizeByChange(index)}
                  value={normalizeByProperties?.[yAxis.key]?.label}
                />
              </MenuItemControl>
              <MenuItemControl
                label='Limits (Min - Max)'
                inputId='x-axis-limits'
              >
                <Numeric_Filter
                  placeholder='Min'
                  onBlur={makeOnRangeValueChange(index, RangeValue.Min)}
                  value={yPropertyRanges[index]?.[0] ?? undefined}
                  sx={{ '.MuiInputBase-input': { fontSize: '0.75rem' } }}
                />
                <Numeric_Filter
                  placeholder='Max'
                  onBlur={makeOnRangeValueChange(index, RangeValue.Max)}
                  value={yPropertyRanges[index]?.[1] ?? undefined}
                  sx={{ '.MuiInputBase-input': { fontSize: '0.75rem' } }}
                />
              </MenuItemControl>
              {yAxisProperties.length > 1 && (
                <Button
                  type='text'
                  size='small'
                  onClick={onRemoveAxis(index)}
                  className='text-xs font-semibold self-end px-0.5 gap-x-1 text-gray-500'
                >
                  <TrashCan size={14} />
                  Remove Y-Axis {index + 1}
                </Button>
              )}
            </Fragment>
          ))}
          {yAxisProperties.length < MAX_Y_AXIS_METRICS && (
            <Button
              variant='text'
              color='primary'
              size='small'
              onClick={onAddAxis}
              className='text-xs font-semibold self-start px-0.5'
            >
              <Add size={16} />
              Add Y-Axis
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
