import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'

import { UploadDatasets } from '@/datasets/upload/UploadDatasets'
import CellSpecifications from '@/insights/cell-specifications/CellSpecifications'
import { Insight_Dashboard } from '@/insights/home/Insight_Dashboard'
import { ChartList } from '@/insights/reports/ChartList'
import {
  AddVisualizations,
  ReportBuilder,
  ReportPreview,
  ReportView,
  SelectDatasets
} from '@/insights/reports/ReportBuilder'
import { ReportList } from '@/insights/reports/ReportList'

import { Error_Fallback } from './Error_Fallback'
import { Navigation_Root } from './Navigation_Root'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/'
      element={<Navigation_Root />}
      errorElement={<Error_Fallback />}
    >
      <Route path=':organization_key'>
        <Route path=':workspace_key' element={<Outlet />}>
          <Route index element={<Navigate to='home' />} />
          <Route path='home' element={<Insight_Dashboard />} />
          <Route path='reports' element={<Outlet />}>
            <Route index element={<ReportList />} />
            <Route path='item/:reportId' element={<ReportView />} />
            <Route path=':reportId' element={<ReportBuilder />}>
              <Route index element={<Navigate to='data' />} />
              <Route path='data' element={<SelectDatasets />} />
              <Route path='visualizations' element={<AddVisualizations />} />
              <Route path='preview' element={<ReportPreview />} />
            </Route>
          </Route>

          <Route path='charts' element={<Outlet />}>
            <Route index element={<ChartList />} />
          </Route>
          <Route path='cell-specifications' element={<CellSpecifications />} />
          <Route path='upload-datasets' element={<UploadDatasets />} />
          <Route path='alerts' element={<>Coming Soon</>} />
        </Route>
      </Route>
    </Route>
  )
)

export const Navigation = () => {
  return <RouterProvider router={router} />
}
