import { XAxis } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { FocusEvent } from 'react'

import { Select } from '@/components'
import {
  Filter_Option,
  Normalize_Type,
  Property_Option
} from '@/gql_generated/graphql'
import Numeric_Filter from '@/insights/controls/filters/Numeric_Filter'
import { Normalize_By_Option } from '@/insights/jotai/charts.atoms'
import { mapKeyToValue } from '@/utils'

import { RangeValue } from '../../../../constants'
import {
  VisualizationType,
  visualizationAtomFamily
} from '../../../../store/report.molecule'
import { MenuHeader, MenuItemControl } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

type PanelXAxisProps = {
  propertyOptions: (Property_Option | Filter_Option)[]
  normalizeOptions?: Normalize_By_Option[]
  loading?: boolean
}

/**
 * PanelXAxis component for configuring the X-Axis of a chart visualization.
 *
 * @param {PanelXAxisProps} props - The properties for the component.
 */
export function PanelXAxis(props: PanelXAxisProps) {
  const { propertyOptions, normalizeOptions = [], loading = true } = props
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()
  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, type } = visualization
  if (type !== VisualizationType.Chart) return null

  const { xAxisProperty, xPropertyRange, normalizeByProperties } = config

  /**
   * Handles the change of the metric for the X-axis.
   *
   * @param {string} newMetric - The new property to be set for the X-axis.
   */
  const onMetricChange = (newMetric: string) => {
    if (propertyOptions == null) return
    const xAxisProperty = propertyOptions.find(({ key }) => key === newMetric)
    updateVisualizationConfig({ xAxisProperty })
  }

  /**
   * Handles the change of the normalization metric for the X-axis.
   *
   * @param {string} normalizeBy - The new property to be set for the X-axis normalization.
   */
  const onNormalizeByChange = (normalizeBy: string) => {
    const { key } = xAxisProperty
    updateVisualizationConfig({
      normalizeByProperties: {
        ...normalizeByProperties,
        [key]: {
          type: Normalize_Type.DatasetProperty,
          key: normalizeBy,
          label: normalizeBy
        }
      }
    })
  }

  /**
   * Creates a handler for changing the range value of the X-axis.
   *
   * @param {RangeValue} pos - The position (Min or Max) of the range value.
   */
  const makeOnRangeValueChange =
    (pos: RangeValue) => (event: FocusEvent<HTMLInputElement, Element>) => {
      const value = parseFloat(event.target.value)
      updateVisualizationConfig({
        xPropertyRange: [
          pos === RangeValue.Min ? value : xPropertyRange[0],
          pos === RangeValue.Max ? value : xPropertyRange[1]
        ]
      })
    }

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <XAxis />
            X-Axis
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-2 items-stretch'>
          <MenuItemControl
            label='X-Axis'
            inputId='x-axis-type'
            emphasize
            loading={loading}
          >
            <Select
              allowClear={false}
              aria-label='X-Axis Type'
              id='x-axis-type'
              options={mapKeyToValue(propertyOptions)}
              placeholder='Add Metric'
              showSearch
              className='flex-1'
              value={xAxisProperty}
              onChange={onMetricChange}
            />
          </MenuItemControl>
          <MenuItemControl
            label='Normalize by'
            inputId='x-axis-normalize-by'
            loading={loading}
          >
            <Select
              allowClear
              aria-label='X-Axis Normalize By'
              id='x-axis-normalize-by'
              options={mapKeyToValue(normalizeOptions)}
              placeholder='Add Metric'
              showSearch
              className='flex-1'
              value={normalizeByProperties?.[xAxisProperty.key]?.label}
              onChange={onNormalizeByChange}
            />
          </MenuItemControl>
          <MenuItemControl label='Limits (Min - Max)' inputId='x-axis-limits'>
            <Numeric_Filter
              placeholder='Min'
              onBlur={makeOnRangeValueChange(RangeValue.Min)}
              value={xPropertyRange[0] ?? undefined}
              sx={{ '.MuiInputBase-input': { fontSize: '0.75rem' } }}
            />
            <Numeric_Filter
              placeholder='Max'
              onBlur={makeOnRangeValueChange(RangeValue.Max)}
              value={xPropertyRange[1] ?? undefined}
              sx={{ '.MuiInputBase-input': { fontSize: '0.75rem' } }}
            />
          </MenuItemControl>
        </div>
      </div>
    </div>
  )
}
