import { Logout, PersonOutline } from '@mui/icons-material'
import {
  Avatar,
  Box,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  styled
} from '@mui/material'
import { useRef, useState } from 'react'

import { use_logout } from '../../../auth/hooks/use_logout'
import { use_app_selector } from '../../../state/redux/hooks'
import { select_user_email } from '../../../user/user_slice'
import { DRAWER_WIDTH } from '../Left_Nav.constants'
import { Left_Nav_List_Item } from './Left_Nav_List_Item'

interface User_Nav_Item_Props {
  is_left_nav_open: boolean
}

const NavItemsWrapper = styled(Box)<User_Nav_Item_Props>(
  ({ theme, is_left_nav_open }) => ({
    paddingInline: theme.spacing(is_left_nav_open ? 1 : 0.5),
    overflowX: 'hidden'
  })
)

export const User_Nav_Item = ({ is_left_nav_open }: User_Nav_Item_Props) => {
  const user_email = use_app_selector(select_user_email)
  const logout = use_logout()
  const list_item_ref = useRef<HTMLUListElement>(null)

  const [menu_anchor_element, set_menu_anchor_element] =
    useState<HTMLElement | null>(null)

  const open = Boolean(menu_anchor_element)

  const handle_open = () => {
    set_menu_anchor_element(list_item_ref.current)
  }
  const handle_close = () => {
    set_menu_anchor_element(null)
  }

  return (
    <NavItemsWrapper is_left_nav_open={is_left_nav_open}>
      <List ref={list_item_ref}>
        <Left_Nav_List_Item
          label={user_email || ''}
          is_left_nav_open={is_left_nav_open}
          icon={
            <Avatar sx={{ width: 36, height: 36 }}>
              <PersonOutline />
            </Avatar>
          }
          onClick={handle_open}
        />
        <Menu
          id='account-menu'
          aria-labelledby='account-menu'
          anchorEl={menu_anchor_element}
          open={open}
          onClose={handle_close}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: open ? 'center' : 'right'
          }}
        >
          <MenuItem onClick={logout} sx={{ width: DRAWER_WIDTH - 40 }}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </List>
    </NavItemsWrapper>
  )
}
