import { useQuery } from '@apollo/client'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Normalize_Type, Property_Option } from '@/gql_generated/graphql'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '@/insights/charts/cycle_summaries/queries/get_cycle_summary_properties'

import { PLOTTABLE_PROPERTY_TYPES } from '../../../../types'
import { reportAtomFamily } from '../../../store/report.molecule'
import { ReportRouteParams } from '../../../types'
import { SelectChartType } from './controls/SelectChartType'
import { PanelCycleFilters, PanelXAxis, PanelYAxis } from './panels'

export function MenuDatasetChart() {
  const { reportId } = useParams<ReportRouteParams>()
  const report = useAtomValue(reportAtomFamily({ id: reportId }))
  const { workspaceId, organizationId } = report

  const {
    data: cycleSummaryPropertiesData,
    loading: cycleSummaryPropertiesLoading
  } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organizationId as string
    },
    skip: !organizationId || !workspaceId
  })

  const propertyOptions =
    cycleSummaryPropertiesData?.get_cycle_summary_properties?.dataset_properties?.filter(
      (property: Property_Option) =>
        property.type && PLOTTABLE_PROPERTY_TYPES.includes(property.type)
    ) || []

  const normalizeByOptions = useMemo(() => {
    const datasetProperties: Property_Option[] =
      cycleSummaryPropertiesData?.get_cycle_summary_properties
        ?.dataset_properties || []

    return datasetProperties
      .filter(({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type))
      .map(({ key, label }: Property_Option) => ({
        key,
        label,
        type: Normalize_Type.DatasetProperty,
        group: 'Dataset Metrics'
      }))
  }, [cycleSummaryPropertiesData])

  return (
    <>
      <PanelXAxis
        propertyOptions={propertyOptions}
        normalizeOptions={normalizeByOptions}
        loading={cycleSummaryPropertiesLoading}
      />
      <PanelYAxis
        propertyOptions={propertyOptions}
        normalizeOptions={normalizeByOptions}
        loading={cycleSummaryPropertiesLoading}
      />
      {/* <PanelGroupBy
        groupByOptions={groupByPropertyOptions}
        aggregateByOptions={aggregateByOptions}
        loading={propertiesLoading}
      /> */}
      <PanelCycleFilters />
      <SelectChartType disabled />
    </>
  )
}
